import React from "react";
import { Container, Row, Col } from "react-bootstrap";
export default () => {
  return (
    <Container fluid id="app-theme" theme="light">
      <Row>
        <Col
          className="left-countdown  d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="text-center">
            <img src="/images/logo-light.svg" alt="Eleições Autárquicas 2024" />

            <h5 className="mt-4">Página em manutenção...</h5>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
