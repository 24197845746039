import axios from "axios";

import { trackPromise } from "react-promise-tracker";

import Config from "config";

import { api, dateStringToObject, timeStringToObject } from "utils";

export const setTime = (data) => ({
  type: "SET_TIME",
  data,
});

export const tick = (startTime) => ({
  type: "TICK",
  startTime,
});

export const is_fetching = () => ({
  type: "FETCHING",
});

export const fetchTime = () => {
  return (dispatch) => {
    dispatch(is_fetching());

    const startTime = new Date();

    //let requestDataV = axios.get("/data/version.json?v=" + startTime.getTime());

    api("get-time", null, "GET", true).then((d) => {
 
      console.log("status: ",d);
      dispatch(
        setTime({
          status_site: d.status_site,
          lastUpdate: new Date(d.time),
        })
      );
    });

    /*axios.all([requestDataV]).then(
      axios.spread((...responses) => {
        const resDataV = responses[0];
        const resultsDate = dateStringToObject(resDataV.data.date);
        const resultsTime = timeStringToObject(resDataV.data.time);
        dispatch(
          setTime({
            status_site: resDataV.data.status_site,
            lastUpdate: new Date(
              resultsDate.year,
              resultsDate.month,
              resultsDate.day,
              resultsTime.hours,
              resultsTime.minutes,
              resultsTime.seconds
            ),
          })
        );
      })
    );

    */
  };
};
