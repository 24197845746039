import React, { useEffect } from "react";

import { Component } from "react";

import { connect, useDispatch } from "react-redux";

import { fetchVersion, setUpdate } from "store/elections/actions";

import { getCandidatos } from "store/candidatos/actions";

import { fetchData } from "store/elections/actions";

import { useToasts } from "react-toast-notifications";
import config from "config";
import { getDeputados } from "store/deputados/actions";
import { formatHumanReadable } from "utils";

const AppData = ({ elections, countdown, addToast, children, }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCandidatos());
    dispatch(getDeputados());
  }, []);

  useEffect(() => {
    if (!elections.first) {
      addToast(
        <div>
          <span>{`Dados atualizados`} </span>
          <div className="fw-600">
            {formatHumanReadable({
              date: elections?.update?.date,
              time: elections?.update?.time,
            })}
          </div>
        </div>,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    }
  }, [elections?.update?.version]);

  return <>{children}</>;
};

const withToast = (Component) => {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
};

export default connect((s) => ({ ...s }))(withToast(AppData));
