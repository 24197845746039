import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { format } from "utils";
import _ from "components/Translate";
import Skeleton from "react-loading-skeleton";

export default function LineHorizontal(props) {
  const totalLabel = props.totalLabel || "Total";

  const titleDetail = props.titleDetail || null;

  const clss = props.className || "";

  return (
    <Card className={`card-transparent app-line-h ${clss}`}>
      <Card.Body>
        <h6 className="card-title text-truncate">
          {props.title}
          {titleDetail}
        </h6>

        <div className="d-flex mb-3">
          <span className="app-line-h-value t-value">
            {props.loading ? (
              <Skeleton width={60} height={30} />
            ) : (
              format(props.value)
            )}
          </span>
          <span className="ml-auto app-line-h-pct t-pct">
            {props.loading ? (
              <Skeleton width={60} height={30} />
            ) : (
              <>{props.pct}%</>
            )}
          </span>
        </div>
        <div className="app-line-h-bar-wrapper mb-1">
          <div
            className="bg-line-stat app-line-h-bar-pct "
            style={{ width: `${props.pct}%` }}
          ></div>
        </div>
        <div className="small pt-1 app-line-h-total ">
          <span className="text-uppercase">{totalLabel}</span>{" "}
          <b className="t-total">{format(props.total)}</b>
          {props.totalDescription && <span> {props.totalDescription}</span>}
        </div>
      </Card.Body>
    </Card>
  );
}
