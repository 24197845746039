import React, { Component } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class PieChart extends Component {
  state = {
    chartData: {
      chart: {
        type: "pie",
        height: 150,
        marginBottom: 0,
      },
      title: {
        text: "",
      },
      subtitle: {
        text:
          this.props.data
            .reduce((accumulator, obj) => accumulator + obj.y, 0)
            .toFixed(0) +
          " <br/>" +
          this.props.titleName,
        floating: true,
        style: {
          fontSize: "14px",
          fontWeight: "600",
          color: "var(--body-color)",
        },
        y: 68,
      },
      series: [
        {
          data: this.props.data,
        },
      ],
      tooltip: {
        pointFormat: "<b>{point.y}</b>",
      },
      plotOptions: {
        pie: {
          showInLegend: false,
          innerSize: "80%",
          dataLabels: {
            enabled: false,
            distance: -14,
            color: "white",
            style: {
              fontweight: "bold",
              fontsize: 50,
            },
          },
        },
      },
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        chartData: {
          ...this.state.chartData,
          subtitle: {
            text:
              this.props.data
                .reduce((accumulator, obj) => accumulator + obj.y, 0)
                .toFixed(0) +
              " <br/>" +
              this.props.titleName,
          },
          series: [
            {
              data: this.props.data,
            },
          ],
        },
      });
    }
  }

  render() {
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.chartData}
        />
      </>
    );
  }
}

export default PieChart;
