import React, { useState } from "react";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TextTransition from "react-text-transition";
import { format, imgPath } from "utils";
import ModalElected from "./ModalElected";
import PieChart from "./PieChart";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const candidaturasException = {
  PP: {
    camara: {
      pr: false,
      sd: false,
      bv: false,
    },
  },
};

export default function PartidosStats({
  votos,
  fetching,
  elected,
  selectedRegion,
  regions,
  candidatos,
  deputados,
  electionContext,
  context,
  electedLabel,
}) {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState();

  const getCandidato = (id) => {
    return candidatos.filter((item) => item.id === id)[0] || {};
  };
  const select = (id) => {
    setSelected(id || null);
    setShowModal(true);
  };

  const processElectedData = () => {
    let data = [];

    if (elected && elected.hasOwnProperty("candidates")) {
      elected.candidates.map((item, i) => {
        if (candidatos && candidatos[0]) {
          const candidates =
            candidatos.filter((c) => item.id === c.id)[0] || {};

          data.push({
            name: item.id,
            y: item.elected,
            color: candidates.color,
            image: candidates.image,
          });
        }
      });
    }
    return data;
  };

  return (
    <>
      {showModal && (
        <ModalElected
          selectedRegion={selectedRegion}
          onHide={() => setShowModal(false)}
          context={electedLabel}
          show={showModal}
          id={selected}
          elected={
            selected
              ? elected?.candidates
                  ?.find((i) => i.id === selected)
                  ?.names?.map((n) => deputados.find((d) => d.id === n))
              : elected?.candidates
                  .map((e) => e.names)
                  .flat()
                  ?.map((n) => deputados.find((d) => d.id === n))
          }
          partidos={candidatos}
          deputados={deputados}
          regions={regions}
        />
      )}
      <SkeletonTheme
        color="var(--skeleton-bg)"
        highlightColor="var(--skeleton-highlight-bg)"
      >
        <>
          <div>
            <div
              className="d-flex mb-4"
              style={{ fontSize: 14, fontWeight: 500 }}
            >
              <div className="ml-auto d-flex align-items-start position-relative">
                <div
                  className="text-capitalize text-right"
                  style={{ width: 120, fontWeight: "500", lineHeight: 1.1 }}
                >
                  {context}
                </div>
                <div style={{ width: 112 }} className="">
                  <div
                    className=" ml-auto w-d-flex justify-content-right"
                    style={{ width: "fit-content" }}
                  >
                    <div
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        select();
                      }}
                    >
                      <div
                        className="d-block ml-auto mr-2 text-right"
                        style={{ fontWeight: "500", lineHeight: 1.1 }}
                      >
                        {electedLabel}
                      </div>{" "}
                      <i className="fas fa-ellipsis-v " style={{}}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {fetching ? (
                <div>
                  <Skeleton count={10} className="component-radius"></Skeleton>
                </div>
              ) : (
                <>
                  {votos &&
                    votos.length > 0 &&
                    votos
                      ?.filter((item) => {
                        const hasException =
                          candidaturasException.hasOwnProperty(item.id);
                        if (
                          hasException &&
                          candidaturasException[item.id][electionContext] &&
                          candidaturasException[item.id][electionContext][
                            selectedRegion.code
                          ] === false
                        ) {
                          return false;
                        }
                        return true;
                      })
                      ?.map((item, i) => {
                        const electedData = elected?.candidates?.find(
                          (c) => c.id === item.id
                        );

                        return (
                          <div
                            party-id={item.id}
                            className="pb-3 pb-sm-3 position-relative mb-1 col-12 px-0"
                            key={item.id + "-" + i}
                          >
                            <div className="d-flex align-items-center">
                              <OverlayTrigger
                                placement="top"
                                trigger={["focus", "hover"]}
                                // delay={{ show: 250, hide: 400 }}
                                overlay={
                                  <Tooltip>
                                    {getCandidato(item.id)?.name}
                                  </Tooltip>
                                }
                              >
                                <div className="party-name-logo text-truncate d-flex flex-column ">
                                  <div className="text-center app-voto-candidato d-flex flex-column align-items-center mx-auto">
                                    <img
                                      src={imgPath(
                                        getCandidato(item.id)?.image
                                      )}
                                      alt=""
                                      className=""
                                      width={26}
                                      height={26}
                                      style={{ minWidth: 26, minHeight: 26 }}
                                    />
                                  </div>

                                  <span
                                    className="mt-0 app-voto-name fw-600 d-sm-none t-v-party-name cursor-context-menu text-truncate"
                                    style={{ fontSize: 11 }}
                                  >
                                    {item.id}
                                  </span>
                                </div>
                              </OverlayTrigger>

                              <div className="info-votos pl-1 pl-sm-3 w-100">
                                <div
                                  className="d-flex f position-relative"
                                  style={{
                                    top: -4,
                                    fontSize: 14,
                                    fontWeight: 500,
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="top"
                                    trigger={["focus", "hover"]}
                                    // delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip>
                                        {getCandidato(item.id)?.name}
                                      </Tooltip>
                                    }
                                  >
                                    <span className=" app-voto-name d-none d-sm-block t-v-party-name cursor-context-menu">
                                      {item.id}
                                    </span>
                                  </OverlayTrigger>

                                  <div
                                    className="d-flex gap-1 align-items-center ml-auto "
                                    style={{}}
                                  >
                                    <span
                                      className="ml-auto  app-voto-value text-right body-color t-v-party-value"
                                      style={{}}
                                    >
                                      {format(item.votes)}
                                    </span>

                                    <span
                                      className=" app-voto-pct text-right d-inline-block color-accent t-v-party-perc"
                                      style={{ width: 54 }}
                                    >
                                      {item.pct}%
                                    </span>
                                  </div>

                                  <div
                                    className=" app-voto-pct text-right  cursor-pointer"
                                    style={{ minWidth: 112 }}
                                    onClick={() => {
                                      if (electedData.elected > 0) {
                                        select(item.id);
                                      }
                                    }}
                                  >
                                    <span className="t-v-party-elected">
                                      {electedData.elected}
                                    </span>
                                    <a
                                      className="elected-icon pl-2 cursor-pointer"
                                      style={{ right: -16 }}
                                    >
                                      {electedData.elected > 0 && (
                                        <i className="fas fa-ellipsis-v"></i>
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <div className="app-line-h-bar-wrapper">
                                  <div
                                    className="app-line-h-bar-pct"
                                    style={{
                                      width: `${item.pct}%`,
                                      background: `${
                                        getCandidato(item.id)?.color
                                      }`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </>
              )}
            </div>
          </div>
          <div style={{ width: 270 }} className="ml-auto">
            <div className="d-flex align-items-center">
              <div className="d-block w-100 p-1" style={{ maxWidth: "65%" }}>
                <PieChart data={processElectedData()} titleName={"Eleitos"} />
              </div>
              <div style={{ width: "35%" }}>
                <div className="text-uppercase font-weight-bold mb-2 elected-item text-center">
                  <span className="d-block elected-value body-color t-v-ele-tobe">
                    {elected?.toelect}
                  </span>
                  <span className="d-block elected-label">Por Eleger</span>
                </div>
                <div className="text-uppercase font-weight-bold elected-item text-center">
                  <span className="d-block elected-value body-color t-v-ele-total">
                    {elected?.total}
                  </span>
                  <span className="d-block elected-label">Total</span>
                </div>
              </div>
            </div>
          </div>
        </>
      </SkeletonTheme>
    </>
  );
}

const Loading = () => {
  const arr = (function () {
    var r = [];
    for (var i = 0; i < 7; i++) {
      r.push(i);
    }
    return r;
  })();

  return arr.map((i) => (
    <div
      className="pb-3 position-relative mb-1 col-sm-6 col-md-12 px-lg-0"
      key={i}
    >
      <div className="d-flex align-items-center">
        <Skeleton width={26} height={26} circle={true}></Skeleton>
        <div className="info-votos pl-3 w-100">
          <div className="d-flex">
            <div
              className="d-flex font-weight-bold position-relative"
              style={{ top: -2 }}
            >
              <Skeleton width={40}></Skeleton>
            </div>
          </div>
          <Skeleton width={"100%"}></Skeleton>
        </div>
      </div>
    </div>
  ));
};
