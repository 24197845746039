import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import _ from "../Translate";
import PieChart from "./PieChart";
import Modal from "./ModalElected";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import TextTransition from "react-text-transition";
import { imgPath } from "utils";

const Loading = () => {
  const arr = (function () {
    var r = [];
    for (var i = 0; i < 7; i++) {
      r.push(i);
    }
    return r;
  })();
  return arr.map((i) => (
    <div className={`d-flex  pb-2 pt-2 position-relative `} key={i}>
      <Skeleton width={26} height={26} circle={true}></Skeleton>
      <div className="info-votos pl-5 pr-2 w-100">
        <Skeleton width={20}></Skeleton>
        <Skeleton width={"100%"}></Skeleton>
      </div>
    </div>
  ));
};

class ElectedDeputies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      id: "",
    };
  }

  chartData = (elected) => {
    let data = [];

    if (elected && elected.hasOwnProperty("candidates")) {
      elected.candidates.map((item, i) => {
        if (this.props.candidatos && this.props.candidatos[0]) {
          const candidates =
            this.props.candidatos.filter((c) => item.id === c.id)[0] || {};

          data.push({
            name: item.id,
            y: item.elected,
            color: candidates.color,
            image: candidates.image,
          });
        }
      });
    }

    return data;
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  onClick = (id) => {
    let candidates = this.getElectedData().candidates || [];

    candidates = id ? candidates.filter((item) => item.id === id) : candidates;

    let elected = [];

    candidates.map((c, i) => {
      elected = [...elected, ...c.names];
    });

    //if(elected[0])

    this.setState({
      show: true,
      id: id,
      //regions      : this.props.regions,
      elected: elected,
      searchResult: elected,
    });
  };

  getElectedData() {
    const { context, elections } = this.props;

    const dataContext = elections.data[context];

    const selectedRegion = elections.selected;

    const electedData =
      dataContext &&
      dataContext.graphics &&
      dataContext.graphics[selectedRegion]
        ? dataContext.graphics[selectedRegion]
        : {};

    return electedData.elected || {};
  }

  render() {
    const { context } = this.props;

    const elected = this.getElectedData();

    const _data = this.chartData(elected);

    const shadow = this.props.shadow === false ? "" : "shadow";

    let _context = context === "camara" ? _("VERIADORES") : _("DEPUTADOS");

    const animateTitle = this.props.animateTitle == false ? false : true;

    return (
      <>
        {this.state.show && (
          <Modal
            hideModal={this.hideModal}
            context={context}
            show={this.state.show}
            id={this.state.id}
            elected={this.state.elected}
            partidos={this.props.candidatos}
          />
        )}

        <Card className={`border-0 app-elected px-2 mb-4 ${shadow}`}>
          <Card.Body className="py-lg-4">
            <div
              className={`position-relative elected-title ${
                elected.value ? "cursor-pointer" : ""
              }`}
              onClick={elected.value ? () => this.onClick("") : () => {}}
            >
              <h6 className="font-weight-bold mb-3 pr-4">
                <TextTransition inline text={_context} />
                <span> {_("ELEITOS")}</span>
              </h6>
              {elected.value ? (
                <div className="position-absolute elected-operation">
                  <a className="elected-icon  pl-2">
                    <i className="fas fa-ellipsis-v"></i>
                  </a>
                </div>
              ) : (
                <div />
              )}
            </div>

            <Row>
              <Col md="8" className="p-0">
                <PieChart data={_data} titleName={_("ELEITOS")} />
              </Col>
              {elected && (
                <Col md="4" className="pl-0 mt-4 text-center">
                  <div className="text-uppercase font-weight-bold mb-2 elected-item">
                    <span className="d-block elected-value">
                      {elected.toelect}
                    </span>
                    <span className="d-block elected-label">Por Eleger</span>
                  </div>
                  <div className="text-uppercase font-weight-bold elected-item">
                    <span className="d-block elected-value">
                      {elected.total}
                    </span>
                    <span className="d-block elected-label">Total</span>
                  </div>
                </Col>
              )}
            </Row>
            {this.props.isFetching && <Loading></Loading>}
            <SkeletonTheme
              color="#e8e8e8"
              highlightColor="#fff"
              className="w-100"
            >
              <div className="holder-elected-info mt-5 ">
                {_data.map((item, i) => {
                  const hasElected = item.y > 0;

                  const showElected = hasElected
                    ? () => {
                        this.onClick(item.name);
                      }
                    : () => {
                        return;
                      };

                  const cursorClss = hasElected ? "cursor-pointer" : "";

                  return (
                    <div
                      party-id={item.name}
                      className={`d-flex  pb-2 pt-2 position-relative ${cursorClss}`}
                      key={item.name + "-" + i}
                      onClick={showElected}
                    >
                      <div className="text-center app-voto-candidato position-absolute">
                        <img src={imgPath(item.image)} alt={item.name} />
                      </div>

                      <div className="info-votos pl-5 pr-2">
                        <span className="app-voto-name d-block">
                          {item.name}
                        </span>
                        <div className="app-line-h-bar-wrapper">
                          <div
                            className="app-line-h-bar-pct"
                            style={{ background: `${item.color}` }}
                          ></div>
                        </div>
                      </div>

                      <div className="position-absolute elected-operation">
                        <span className="app-voto-value pr-2">{item.y}</span>
                        <a className="elected-icon  pl-2">
                          {item.y > 0 && <i className="fas fa-ellipsis-v"></i>}
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </SkeletonTheme>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default connect((s) => ({ ...s }))(ElectedDeputies);

//export default ElectedDeputies//connect( (s)=>({...s}) )(ElectedDeputies)
