import React, { useState, useEffect } from "react";
import { api } from "utils";
let timeout;

const debounce = (func, delay) => {
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const SearchZonas = ({ currentRegion, onSelect }) => {
  const [input, setInput] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isInputVisible, setIsInputVisible] = useState(false);

  const toggleInputVisibility = () => {
    setIsInputVisible(!isInputVisible);
  };

  const debouncedSearch = debounce((value) => {
    setSearchInput(value);
  }, 1200);

  useEffect(() => {
    if (!searchInput) {
      setResults([]);
      return;
    }
    const fetchResults = async () => {
      setLoading(true);
      try {
        const data = await api("search-zonas", {
          q: searchInput,
          type: "camara",
          ...(currentRegion.code !== "nacional"
            ? { region: currentRegion.code }
            : {}),
        });
        setResults(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [searchInput]);

  const handleInputChange = (event) => {
    setInput(event.target.value);
    debouncedSearch(event.target.value);
  };

  return (
    <div className="ml-auto">
      <div className="">
        {!isInputVisible && (
          <div className="">
            <button
              className="btn bg-color btn-sm  bg-component component-radius app-zonas-modal-btn border component-border  app-mapcrumb px-3 py-2"
              type="button"
              onClick={toggleInputVisibility}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        )}
        {isInputVisible && (
          <input
            type="text"
            className="form-control"
            placeholder={`Zonas em: ${
              currentRegion.name === "nacional"
                ? "Cabo Verde"
                : currentRegion.name
            }`}
            autoFocus
            onBlur={() => setIsInputVisible(false)} // Hide input when it loses focus
            value={input}
            onChange={handleInputChange}
          />
        )}
      </div>

      {loading && <p>Loading...</p>}

      <div className="search-zonas-results">
        {results.length > 0 ? (
          <ul>
            {results.map((result, index) => (
              <li
                key={index}
                className="my-2"
                onClick={() => {
                  onSelect({
                    region: result.region,
                    zona: result.nome,
                  });
                }}
              >
                {result.nome}
                <b>{result.region.name}</b>
              </li> // Adjust based on your data structure
            ))}
          </ul>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default SearchZonas;
