import React from "react";

export default function IonIcon({
  name,
  color = "",
  size = "",
  className = "",
  onClick,
}) {
  return (
    <span
      className={`${
        onClick ? "cursor-pointer" : ""
      } fs-${size} d-flex align-items-center justify-content-center ${className}`}
      style={{
        ...(color ? { color: color } : null),
        width: "fit-content",
      }}
      onClick={onClick || null}
    >
      <ion-icon name={name}></ion-icon>
    </span>
  );
}
