import Theme, { Graphics } from "components/Theme";
import React, { useEffect, useState } from "react";
import { Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import moment from "moment-timezone";
import "moment/locale/pt";

import _config from "config";
import Footer from "components/Theme/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { setSiteStatus, setTheme } from "store/elections/actions";
import IonIcon from "components/Icon";
import { fetchTime } from "store/countdown/actions";
import AppLoading from "components/AppLoading";
import { CSSTransition } from "react-transition-group";

moment.locale("pt");

const WaitingPage = function ({ elections, countdown }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(countdown?.lastUpdate ? true : false);
  const { theme } = elections;
  const date = _config?.dates?.showElection;
  const monthName = moment().month(date.month).format("MMMM");

  useEffect(() => {
    let interval;
    dispatch(fetchTime());
    interval = setInterval(() => {
      if (document.hasFocus()) {
        dispatch(fetchTime());
      }
    }, _config.versionRequestTimeout);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown.lastUpdate) {
      setTimeout(() => {
        setShow(true);
      }, 1800);
    }
  }, [countdown.lastUpdate]);

  useEffect(() => {
    if (countdown.status_site) dispatch(setSiteStatus(countdown.status_site));
  }, [countdown.status_site]);

  return (
    <>
      {!show && <AppLoading />}
      <CSSTransition in={show} timeout={400} classNames="fade" unmountOnExit>
        <>
          <Graphics />
          <Container
            fluid
            id={`app-theme`}
            className={` px-0  no-sidebar pb-0`}
            theme={theme}
          >
            <div className="app-container position-relative countdown-page">
              <Row
                className="app-contents px-xl-4 px-sm-2 mx-0 d-flex justify-content-center flex-column"
                style={{ minHeight: "100vh" }}
              >
                <div className="container position-relative">
                  <div
                    className="bg-component-blured px-4 mt-5 component-shadow component-radius d-flex flex-column align-items-center text-center"
                    style={{
                      backdropFilter: "blur(0px)",
                      boxShadow: "0px 40px 80px 0px #0000001A",
                      paddingTop: 100,
                      paddingBottom: 100,
                      minHeight: "80%",
                    }}
                  >
                    <img
                      src={`/images/logo-${theme}.svg`}
                      style={{ maxWidth: "100%", width: 250, marginBottom: 60 }}
                    ></img>

                    <div>
                      <div className="election-day  fw-600 body-color">
                        <span>{date.day}</span> de&nbsp;
                        <span className="text-capitalize">{monthName}</span>
                        &nbsp;
                        <span>de {date.year}</span>
                      </div>

                      <div className="mb-3  px-4 fw-300" style={{}}>
                        <span>
                          Acompanhe os{" "}
                          <b className="fw-600">resultados provisórios</b> a
                          partir das{" "}
                        </span>
                        <span className="h3 d-block m-0 fw-500 mt-4 text-accent">
                          {_config.dates.showElection.hours}
                          {_config.dates.showElection.minutes === 0
                            ? ""
                            : _config.dates.showElection.minutes >= 10
                            ? ":" + _config.dates.showElection.minutes
                            : `:0${_config.dates.showElection.minutes}`}{" "}
                          horas
                        </span>{" "}
                        <span>de Cabo Verde</span>
                      </div>
                    </div>

                    <div className="mt-3 mt-md-5">
                      {_config.local_de_voto.show && (
                        <Link
                          to="/local-de-voto"
                          className="p-3 d-flex align-items-center gap-3 local-voto-btn"
                        >
                          <span>
                            Consulte seu <b>Local de Voto</b>{" "}
                          </span>
                          <IonIcon name="arrow-forward-outline" size="24" />
                        </Link>
                      )}
                    </div>
                  </div>
                  <OverlayTrigger
                    placement="top"
                    style={{ zIndex: 150000 }}
                    overlay={<Tooltip className="small">Mudar tema</Tooltip>}
                  >
                    <a
                      href="#"
                      className="mt-5 theme-toggle d-block "
                      style={{
                        color: "var(--body-color)",
                        zIndex: 10,
                        fontSize: 18,
                        position: "absolute",
                        right: 30,
                        top: 15,
                      }}
                      onClick={() => {
                        dispatch(
                          setTheme(theme === "light" ? "dark" : "light")
                        );
                      }}
                    >
                      <div style={{}}>
                        <IonIcon
                          className="ml-0"
                          name={
                            theme === "light" ? "sunny-outline" : "moon-outline"
                          }
                          size="24"
                        />
                      </div>
                    </a>
                  </OverlayTrigger>
                  <Footer></Footer>
                </div>
              </Row>
            </div>
          </Container>
        </>
      </CSSTransition>
    </>
  );
};

export default connect((state) => ({ ...state }))(WaitingPage);
