import React from "react";
import MapView from "./Components/MapView";
import axios from "axios";
import config from "config";
import _ from "components/Translate";
import { renderToStaticMarkup } from "react-dom/server";
import { Container, Row, Col, Card, Modal, Tabs, Tab } from "react-bootstrap";

import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";

import MesaDetails from "components/Charts/MesaDetails";

import { useToasts } from "react-toast-notifications";
import IonIcon from "components/Icon";

const defaultCenter = { lat: 16, lng: -24 };

const defaultZoom = 7;

let L;

const Popup = (props) => {
  return (
    <div className="app-mesas-popup">
      <div>
        <div className="d-block mb-2">
          {props.DESCRICAO} <br></br>
          <b>{props.MES_REFERE}</b>{" "}
        </div>
      </div>
    </div>
  );
};

const withToast = (Component) => {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      center: defaultCenter,
      zoom: defaultZoom,
      filter: false,
      selected: null,
      selectedData: null,
      enabledFeatures: [],
    };

    this.mapClick = this.mapClick.bind(this);
    this.markerClick = this.markerClick.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.config = config.gis;

    this.regionsRequestIds = {};

    L = window.L;
  }

  componentDidMount() {
    fetch(config.gis.dataUrl)
      .then((c) => {
        return c.json();
      })
      .then((data) => {
        data.id = "app-geojson-data";
        this.setState({
          data: data,
        });
      })
      .catch((dataError) => {
        console.log(dataError);
      });
  }

  fetchMapData(code) {
    const { version } = this.props.elections.update;

    return trackPromise(
      axios.get(`/data/${version}/mesas/${code}.json`).then((d) => {
        return d.data;
      })
    );
  }

  componentDidUpdate() {
    /*this.lasUpdateVersion = {};
    this.geoJSONLayer = null;
    this.geoJSONFeatures = null;*/
  }

  markerClick(e) {
    const feature = e.layer && e.layer.feature ? e.layer.feature : e;
    const { properties } = feature;
    const code = properties.SIGLA_CONC;
    const { version } = this.props.elections.update;

    if (code && version) {
      trackPromise(
        axios
          .get(`/data/${version}/mesas/${code}.json`)
          .then((d) => {
            const mesaData = d.data.filter(
              (m) => m.code === properties.MES_REFERE
            )[0];
            if (mesaData) {
              this.setState({
                selected: feature,
                selectedData: mesaData,
              });
            } else {
              this.props.addToast(
                <div className="fs-12">
                  Mesa por apurar
                  <br />
                  <b style={{ fontSize: 13 }}>{properties.DESCRICAO}</b>
                </div>,
                {
                  appearance: "warning",
                  autoDismiss: true,
                }
              );
            }
          })
          .catch((err) => {
            console.log(err);
          })
      );
    }
  }

  mapClick(e) {
    this.clearSelected();
  }

  getAppGeoJSONLayer(m) {
    let map = m || this.mapReference;

    const layer = Object.values(map._layers).filter(
      (l) => l.options.id === "app-markers-cluster"
    )[0];
    this.geoJSONLayer = layer;
    this.geoJSONFeatures = layer.getLayers();
    return layer;
  }

  getFeatureByCode(map, code) {
    const layer = this.geoJSONLayer || this.getAppGeoJSONLayer(map);
    return this.geoJSONFeatures.filter(
      (item) => item.feature.properties.MES_REFERE === code
    )[0];
  }

  async onMoveEnd(e) {
    const map = e.target;

    if (map.getZoom() >= 7) {
      const regions = {};

      map.eachLayer((layer) => {
        if (
          layer instanceof L.Marker &&
          map.getBounds().contains(layer.getLatLng())
        ) {
          const featureLayer = layer.getAllChildMarkers
            ? map.getZoom() > 10
              ? layer.getAllChildMarkers()[0]
              : {}
            : layer;
          const region =
            featureLayer.feature && featureLayer.feature.properties
              ? featureLayer.feature.properties.SIGLA_CONC
              : null;
          if (region) regions[region] = true;
        }
      });

      const dataResults = await Promise.all(
        Object.keys(regions).map(async (r) => this.fetchMapData(r))
      );

      const enabledCodes = dataResults?.flat()?.map((i) => i.code);

      Object.keys(regions).forEach((r) => {
        if (!this.regionsRequestIds[r]) {
          this.regionsRequestIds[r] = true;
          enabledCodes.forEach((code) => {
            const feature = this.getFeatureByCode(map, code);
            if (feature) {
              const icon = new L.DivIcon({
                html: "<span class='app-map-icon closed shadow blinking '></span>",
                className: "text-center ",
                iconSize: new L.Point(10, 10),
              });
              feature.setIcon(icon);
            }
          });
        }
      });
    }
  }

  clearSelected() {
    this.setState({
      selected: null,
      selectedData: null,
    });
  }

  onFilterClick(filter) {
    this.setState({
      filter: filter,
    });
  }

  addBufferToGeojson(geojson, bufferDistance) {
    const offset = bufferDistance / 1000; // Convert meters to degrees (approx.)
    return geojson.features.map((feature) => {
      if (feature.geometry?.coordinates) {
        const [lng, lat] = feature.geometry.coordinates;
        // Apply an offset in the longitude and latitude directions
        const newLng = lng + (Math.random() - 0.5) * offset;
        const newLat = lat + (Math.random() - 0.5) * offset;
        feature.geometry.coordinates = [newLng, newLat];
      }

      return feature;
    });
  }

  render() {
    const L = window.L;
    const featureSelected = this.state.selected;

    this.regionsRequestIds = {};

    return (
      <Container fluid={true} className="container-fluid">
        <Row className="app-stats-wrapper">
          <Col sm="12" className="p-0">
            <MapView
              filter={this.state.filter}
              regionsList={this.props.regions?.data}
              config={this.config}
              zoom={this.state.zoom}
              center={this.state.center}
              data={this.state.data}
              mapClick={this.mapClick}
              markerClick={this.markerClick}
              selected={this.props.elections.selected}
              whenReady={(o) => {
                this.mapReference = o.target;

                //console.log("ready");
              }}
              onEachFeature={(feature, layer) => {
                const popup = renderToStaticMarkup(
                  <Popup {...feature.properties} />
                );
                layer.bindPopup(popup);
              }}
              onMouseOver={(e) => {
                e.layer.openPopup();
              }}
              onMouseOut={(e) => {
                setTimeout(() => {
                  e.layer.closePopup();
                }, 500);
              }}
              onMoveend={this.onMoveEnd.bind(this)}
            />

            <div className="position-absolute app-map-legend component-radius bg-color  p-2 shadow d-flex gap-1 items-center">
              <div
                className="app-legend d-flex align-items-center gap-1  w-100"
                style={{ width: "fit-content" }}
                onClick={() => {}}
              >
                <span
                  className="blinker"
                  style={{
                    width: 12,
                    height: 12,
                    borderRadius: "100%",
                    background: "var(--mesa-on)",
                  }}
                ></span>
                <span className="text-uppercase" style={{ fontSize: 12 }}>
                  Mesa apurada
                </span>
              </div>
              <div
                className="app-legend d-flex align-items-center gap-1 w-100"
                style={{}}
              >
                <span
                  style={{
                    width: 12,
                    height: 12,
                    borderRadius: "100%",
                    background: "var(--mesa-off)",
                  }}
                ></span>
                <span className="text-uppercase" style={{ fontSize: 12 }}>
                  Sem resultados
                </span>
              </div>
            </div>

            {featureSelected && (
              <Modal
                size="xl"
                className="app-mesas-modal"
                show={this.state.selectedData ? true : false}
                onHide={this.clearSelected.bind(this)}
              >
                <Modal.Header
                  className="border-0 position-sticky top-0 bg-color-lighter component-shadow pr-5"
                  style={{ zIndex: 10 }}
                >
                  <div className="d-flex flex-column gap-2 w-100">
                    <div className="modal-title w-100 d-flex  gap-2">
                      <div className="d-flex flex-column flex-lg-row align-items-lg-center w-100">
                        <div>{featureSelected.properties.DESCRICAO}</div>
                      </div>
                      <div
                        className="px-2 position-absolute cursor-pointer"
                        style={{ top: 12, right: 0 }}
                        onClick={this.clearSelected.bind(this)}
                      >
                        <IonIcon name="close-outline" size="24" />
                      </div>
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body className="p-0 m-0 app-data-wrapper">
                  <div className="p-3 bg-color">
                    <div className="d-flex flex-column gap-2">
                      <div>
                        <span className="fw-500">Mesa</span>{" "}
                        {featureSelected.properties.MES_REFERE}
                      </div>
                      <div className="fw-500">
                        <span>Nome de Posto</span>{" "}
                        {featureSelected.properties.POSTO}
                      </div>
                    </div>
                  </div>
                  <Tabs defaultActiveKey="camara" className="mx-0 ">
                    <Tab eventKey="camara" title="Câmara">
                      <div
                        className="app-data-wrapper"
                        region-selected={featureSelected.properties.SIGLA_CONC}
                        context="camara"
                      >
                        <MesaDetails
                          mesa={featureSelected.properties}
                          data={this.state.selectedData}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey="assembleia" title="Assembleia">
                      <div
                        className="app-data-wrapper"
                        region-selected={featureSelected.properties.SIGLA_CONC}
                        context="assembleia"
                      >
                        <MesaDetails
                          mesa={featureSelected.properties}
                          data={this.state.selectedData}
                          prefix="_aux"
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </Modal.Body>
              </Modal>
            )}
          </Col>
        </Row>

        <Container
          fluid={true}
          style={{ maxWidth: this.config.containerWidth }}
          className="app-data-wrapper"
        >
          {/**
           *
           * <GlobalStats onFilter={ this.onFilterClick } config={this.config} data={this.state.data} selected={ this.state.selected }></GlobalStats>
           */}
        </Container>
      </Container>
    );
  }
}

export default connect((s) => ({ ...s }))(withToast(App));
