import React from "react";
import { connect } from "react-redux";
import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { format, imgPath } from "utils";
import _ from "../Translate";

import TextTransition from "react-text-transition";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const NaFrente = (props) => {
  const Ids = props.ids && props.ids[0] ? props.ids : [];

  const selectedRegion = props.elections.selected;

  const context = props.context;

  const highlighted =
    selectedRegion == "nacional" ? _(context.toUpperCase() + "S") : _("VOTOS");

  return (
    <Card className="card-transparent app-nafrente">
      <Card.Body>
        <>
          <h6 className="card-title  text-truncate">
            Mais
            <TextTransition
              text={highlighted}
              inline
              className="ml-1 destak bolder"
            />
          </h6>
          <Row>
            {Ids[0] && (
              <Col className="d-flex flex-wrap">
                <div className="d-flex">
                  {Ids.map((c, i) => {
                    const candidato = props.candidatos.filter(
                      (item) => item.id === c
                    )[0];
                    return candidato ? (
                      <OverlayTrigger
                        key={`candidato-na-frente-${candidato.id}`}
                        placement="top"
                        trigger={["focus", "hover"]}
                        // delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip>{candidato?.name}</Tooltip>}
                      >
                        <div
                          className="text-center app-nafrente-candidato p-1"
                          style={{ width: "70px" }}
                        >
                          <img
                            src={imgPath(candidato.image)}
                            style={{ height: "32px" }}
                            className="shadow"
                            alt=""
                          />
                          <div className="fw-600 text-truncate">
                            {candidato.id}
                          </div>
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <div key={i}>{c}</div>
                    );
                  })}
                </div>

                <div className="pl-3">
                  <div className="">
                    <b className="app-nafrente-value">{format(props.value)}</b>{" "}
                    {selectedRegion == "nacional"
                      ? _(context.toUpperCase() + "S")
                      : _("VOTOS")}
                  </div>
                  <div>{props.pct}%</div>
                </div>
              </Col>
            )}

            {!Ids[0] && (
              <>
                {props.loading ? (
                  <Col>
                    <small>A carregar dados...</small>
                  </Col>
                ) : (
                  <Col className="align-items-center">
                    <small>Por apurar...</small>
                  </Col>
                )}
              </>
            )}
          </Row>
        </>
      </Card.Body>
    </Card>
  );
};

export default connect((s) => ({ ...s }))(NaFrente);
