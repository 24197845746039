import { SET_DATA, SET_UPDATE, SET_THEME, SET_SELECTED } from "./action-type";

import clean from "resources/cleandata.json";

const initialState = {
  theme: localStorage?.getItem("app-theme") || "light",
  first: true,
  update: {},
  selected: "",
  clean: true,
  isFetching: false,
  data: {
    camara: clean,
    assembleia: clean,
  },
  closedCircles: [],
  newUpdates: [],
  selectedContext: "camara",
};

export default (state = initialState, action) => {
  if (action.type === "RERESH_DATA") {
    return {
      ...state,
      data: {
        ...state.data,
      },
      refreshed: true,
    };
  }

  if (action.type === "FETCHING")
    return {
      ...state,
      isFetching: true,
    };

  if (action.type === "FINISHED_FETCHING")
    return {
      ...state,
      isFetching: false,
    };

  if (action.type === "SET_REGION") {
    return {
      ...state,
      selected: action.data.selected,
    };
  }

  if (action.type === SET_DATA) {
    const data = action.data;
    const camaraData = data.camara;
    const assembleiaData = data.assembleia;

    const camaraGraphics =
      Object.keys(camaraData.graphics).length > 0
        ? camaraData.graphics
        : { [action.code]: clean.graphics.nacional };
    const assembleiaGraphics =
      Object.keys(assembleiaData.graphics).length > 0
        ? assembleiaData.graphics
        : { [action.code]: clean.graphics.nacional };

    const series =
      action.data.camara && action.data.camara.series
        ? action.data.camara.series
        : [];

    const isFirstClosedCircle = state.closedCircles.length === 0;

    const newClosedCircles = series.filter((item) => {
      return item.pct === 100;
    });
    const newUpdates = isFirstClosedCircle
      ? []
      : newClosedCircles.filter(
          (item) => !state.closedCircles.find((c) => c.key === item.key)
        );
    return {
      ...state,
      selected: action.code,
      isFetching: false,
      data: {
        camara: {
          graphics: {
            ...state.data.camara.graphics,
            ...camaraGraphics,
          },
          series: [...action.data.camara.series],
        },
        assembleia: {
          graphics: {
            ...state.data.assembleia.graphics,
            ...assembleiaGraphics,
          },
          series: [...action.data.assembleia.series],
        },
      },
      clean: false,
      first: false,
      closedCircles: newClosedCircles,
      newUpdates,
    };
  }

  if (action.type === SET_UPDATE) {
    return {
      ...state,
      update: {
        ...action.data,
        updated: true,
      },
    };
  }

  if (action.type === SET_THEME) {
    localStorage.setItem("app-theme", action.data);

    return {
      ...state,
      theme: action.data,
    };
  }

  if (action.type === SET_SELECTED) {
    return {
      ...state,
      selected: action.data,
    };
  }

  if (action.type === "SET_CONTEXT") {
    return {
      ...state,
      selectedContext: action.data,
    };
  }

  if (action.type === "SET_SITE_STATUS") {
    return {
      ...state,
      update: {
        ...state.update,
        status_site: action.data,
      },
    };
  }

  return state;
};
