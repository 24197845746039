import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'

export default ()=>{
    return (
        <Container fluid >
            <Row>
                <Col  className="left-countdown text-white d-flex align-items-center justify-content-center" style={ { minHeight:'100vh' } }>
                   <div className="text-center">
                        <img src="/images/logo_w.png" alt="Eleições Autárquicas 2024" />
                       
                        <h5 className="mt-4">Site em Manutenção.</h5>
                   </div>
                </Col>
              
            </Row>
        </Container>
    )
}