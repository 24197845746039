import { combineReducers } from "redux";

import elections from "./elections";
import regions from "./regions";
import candidatos from "./candidatos";
import deputados from "./deputados";

import countdown from "./countdown";

export default combineReducers({
  elections,
  regions,
  candidatos,
  deputados,
  countdown,
});
