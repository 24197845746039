import React from "react";
import { connect } from "react-redux";
import { Breadcrumb } from "react-bootstrap";
import { capitalize } from "utils";

import { selectRegion } from "store/regions/actions";

import { fetchData } from "store/elections/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Config from "config";
import IonIcon from "components/Icon";
const homeCode = "nacional";

const Mapcrumb = ({
  elections,
  regions,
  selectRegion,
  fetchData,
  navigationPrefix = "",
}) => {
  const history = useHistory();
  const isHome = elections.selected === homeCode;
  const home = regions.data.filter((r) => r.code == homeCode)[0];
  const selected = regions.data.filter((r) => r.code == elections.selected)[0];
  const parentCode =
    selected && selected.parent_code ? selected.parent_code : false;
  const parent = parentCode
    ? regions.data.filter((r) => r.code == parentCode)[0]
    : false;
  const { version } = elections.update;
  const homeAttrs = {
    style: {
      textDecoration: "underline",
    },
  };

  if (isHome) homeAttrs.active = true;
  else
    homeAttrs.onClick = () => {
      history.push(
        `/${Config.dashURL + navigationPrefix}/nacional/${
          elections.selectedContext
        }`
      );
    };

  return (
    <Breadcrumb
      className="app-mapcrumb m-0 body-color p-2 bg-component component-radius"
      style={{ height: "fit-content" }}
    >
      {home && (
        <Breadcrumb.Item
          className=" text-accent d-flex align-items-center"
          {...homeAttrs}
        >
          <span className=" d-md-block">{home.name}</span>
        </Breadcrumb.Item>
      )}

      {!isHome && (
        <>
          {selected && (
            <Breadcrumb.Item active className="text-capitalize body-color">
              {selected.name.toLowerCase()}
            </Breadcrumb.Item>
          )}
        </>
      )}
    </Breadcrumb>
  );
};

/*
 {parent && (
                    <Breadcrumb.Item active className="text-capitalize">{parent.name.toLowerCase()}</Breadcrumb.Item>
                )}
*/
const mapState = (s) => ({ ...s });

const mapDispatchToProps = (dispatch) => ({
  fetchData: (code, version) => dispatch(fetchData(code, version)),
});

export default connect(mapState, mapDispatchToProps)(Mapcrumb);
