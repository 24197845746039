import Config from 'config'
import  { SET_REGIONS, SELECT_REGION, FETCHING } from './action-type'

const initialState = {
    selected : {},
    data     : [],
    isFetching : true
};


export default (state = initialState, action) => {

    const defaultRegionCode = (
        Config.defaults && 
        Config.defaults.regions && 
        Config.defaults.regions.selected ? Config.defaults.regions.selected : "nacional"
    );
    
    if(action.type === SET_REGIONS){
        const data= [];

        action.data.forEach((i)=>{
            if( !data.filter(item=>item.code === i.code)[0] )
                data.push(i)
            else
                data[data.indexOf(data.filter( item=>item.code === i.code )[0])] = i   
        });

        return {
           selected : action.data.filter( r=>(  r.code === defaultRegionCode  ) )[0] || action.data[0],
           data     : [...action.data],
           detailedData : data,
           isFetching : false
        }
    }

    if(action.type === SELECT_REGION){
        return {
            ...state,
            selected : action.data
        }
    }

    if(action.type === FETCHING){
     
        return {
            ...state,
            //isFetching : true
        }
    }

  return state;

};