import { Graphics } from "components/Theme";
import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Alert,
  Container,
  Form,
  FormControl,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import moment from "moment-timezone";
import "moment/locale/pt";

import _config from "config";
import Footer from "components/Theme/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { setTheme } from "store/elections/actions";
import IonIcon from "components/Icon";
import MaskInput from "react-maskinput";
import { api } from "utils";
import LocalDeVotoMap from "components/LocalDeVotoMap";
// import { useToasts } from "react-toast-notifications";
// import Toast from "components/Toast";

moment.locale("pt");

const WaitingPage = function ({ elections }) {
  const dispatch = useDispatch();
  // const { addToast } = useToasts();
  const { theme } = elections;

  const date = _config?.dates?.showElection;

  // const monthName = moment().month(date.month).format("MMMM");

  const [loading, setLoading] = useState(false);

  const [nome, setNome] = useState("");
  const [data_nascimento, setData_Nascimento] = useState("");
  const [data_nascimentError, setData_nascimentoError] = useState(null);
  const [result, setResult] = useState();
  const [message, setMessage] = useState();
  const [showMap, setShowMap] = useState();

  const recaptchaRef = useRef(null);

  const resetForm = () => {
    setResult(null);
    setNome("");
    setData_Nascimento("");
    setData_nascimentoError("");
    const nomeInput = document.querySelector("input[ name='nome']");
    nomeInput.focus();
  };

  const focusNome = () => {
    const nomeInput = document.querySelector("input[ name='nome']");
    if (nomeInput) nomeInput.focus();
  };

  const focusDtNascimento = () => {
    const dtNasc = document.querySelector("input[ name='data_nascimento']");
    if (dtNasc) dtNasc.focus();
  };

  const clearNome = () => {
    setNome("");
    focusNome();
  };
  const clearDtNascimento = () => {
    setData_Nascimento("");
    focusDtNascimento();
  };

  const validateDate = (dateString) => {
    const regex = /^\d{2}-\d{2}-\d{4}$/;
    if (!regex.test(dateString)) {
      return false;
    }

    // Split the input into day, month, and year
    const [day, month, year] = dateString.split("-").map(Number);

    // Check if the day is valid (1-31)
    if (day < 1 || day > 31) {
      return false;
    }

    // Check if the month is valid (1-12)
    if (month < 1 || month > 12) {
      return false;
    }

    // Check if the year is realistic (e.g., not in the far past or future)
    const currentYear = new Date().getFullYear();
    if (year < 1900 || year > currentYear) {
      return false;
    }

    // Check if the date is valid using Date object
    const date = new Date(year, month - 1, day);
    // Date validation by comparing with the input (e.g., 31-02-2023 is invalid)
    if (
      date.getDate() !== day ||
      date.getMonth() !== month - 1 ||
      date.getFullYear() !== year
    ) {
      return false;
    }

    return true;
  };

  const sendForm = async (e) => {
    e.preventDefault();
    const resultsElement = document.querySelector(".local-voto-results");

    if (!validateDate(data_nascimento)) {
      setData_nascimentoError("Please enter a valid date (DD-MM-YYYY).");
      return;
    }
    setMessage(null);
    setResult(null);
    setData_nascimentoError("");
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    try{

      if (!token) {
        setMessage({
          text: "Falha ao verificar o reCAPTCHA. Tente novamente.",
          type: "danger",
        });
        setLoading(false);
        return;
      }
  
      setLoading(true);
  
      const verifyCaptcha = await api("verify-captcha", {
        token,
      });
  
      if (verifyCaptcha?.success) {
        try {
          const request = await api("local-de-voto", {
            nome,
            data_nascimento,
          });
  
          if (request?.data?.result?.person) {
            setResult(request.data?.result?.person);
          } else {
            setMessage({
              text: "Não encontramos nenhum resultado. Redefina sua consulta.",
              type: "warning",
            });
          }
        } catch (err) {
          setResult(null);
          setMessage({
            text: "Não foi possivel realizar a consulta.",
            type: "danger",
          });
        } finally {
          setData_nascimentoError("");
          setLoading(false);
          if (window.innerWidth < 992 && resultsElement) {
            resultsElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }else{
        setMessage({
          text: "Não foi possivel realizar a consulta.",
          type: "danger",
        });
        
      }

    }catch(e){
      setMessage({
        text: "Não foi possivel realizar a consulta.",
        type: "danger",
      });
    }finally{
      setLoading(false);
    }

    

    return false;
  };

  const goodToGo = !data_nascimentError && data_nascimento != "" && nome != "";

  return (
    <>
      <Graphics />
      <Container
        fluid
        id={`app-theme`}
        className={` px-0  no-sidebar  pb-0`}
        theme={theme}
      >
        <div className="app-container position-relative countdown-page">
          <Row
            className="app-contents px-xl-4 px-sm-2 mx-0 d-flex justify-content-center flex-column"
            style={{ minHeight: "100vh" }}
          >
            <div className="container position-relative">
              <div
                className="bg-component-blured  my-5 component-shadow component-radius d-flex flex-column "
                style={{
                  backdropFilter: "blur(0px)",
                  boxShadow: "0px 40px 80px 0px #0000001A",
                }}
              >
                <div className="d-flex flex-lg-row flex-column gap-4 gap-lg-5 p-3 p-md-5">
                  <div className="w-100">
                    <div>
                      <Link
                        to="/"
                        className="btn p-0 d-flex align-items-center fw-300 gap-2 text-uppercase text-accent-dark"
                        style={{ fontSize: 14 }}
                      >
                        <span className="p-1 text-accent-dark bg-color shadow rounded-circle ">
                          <IonIcon name="chevron-back-outline" size="20" />
                        </span>
                        Voltar
                      </Link>
                    </div>
                    <div>
                      <h6
                        className="text-uppercase fs-24 text-accent-dark fw-600"
                        style={{ marginTop: 80 }}
                      >
                        <span>Consulte o seu local de voto</span>
                      </h6>
                      <p className="fw-300">
                        Para uma pesquisa mais refinada, preencha os dois campos
                      </p>

                      <Form
                        onSubmit={sendForm}
                        className="api-search d-flex flex-column gap-4 mt-4"
                      >
                        <Form.Group className="m-0">
                          <Form.Label className=" fw-500">
                            Nome Completo
                          </Form.Label>
                          <div className="position-relative">
                            <FormControl
                              name="nome"
                              type="text"
                              required
                              className="bg-color py-3"
                              value={nome}
                              onChange={(e) => setNome(e.target.value)}
                            ></FormControl>
                            {nome.length > 0 && (
                              <a
                                className="btn py-2 px-2 position-absolute right-0"
                                style={{ top: 2 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  clearNome();

                                  return false;
                                }}
                              >
                                <IonIcon
                                  name="close-outline"
                                  size="18"
                                  color="var(--body-color)"
                                />
                              </a>
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group className="m-0">
                          <Form.Label className=" fw-500">
                            Data de Nascimento
                          </Form.Label>
                          <div>
                            <div className="position-relative">
                              <MaskInput
                                placeholder="DD-MM-AAAA"
                                className="form-control bg-color py-3"
                                name="data_nascimento"
                                mask="00-00-0000"
                                size={10}
                                required
                                value={data_nascimento}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  setData_Nascimento(inputValue);

                                  if (inputValue.length === 10) {
                                    if (!validateDate(inputValue)) {
                                      setData_nascimentoError(
                                        "Introduza uma data válida no formato indicado"
                                      );
                                    }
                                  } else {
                                    setData_nascimentoError(""); // Clear error if valid
                                  }
                                }}
                              />

                              {data_nascimento.length > 0 && (
                                <a
                                  className="btn py-2 px-2 position-absolute right-0 cursor-pointer"
                                  style={{ top: 2 }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    clearDtNascimento();
                                    return false;
                                  }}
                                >
                                  <IonIcon
                                    name="close-outline"
                                    size="18"
                                    color="var(--body-color)"
                                  />
                                </a>
                              )}
                            </div>
                            {data_nascimentError && (
                              <span className="text-danger small">
                                {data_nascimentError}
                              </span>
                            )}
                          </div>
                        </Form.Group>
                        <div className="mt-2 d-flex gap-2">
                          <div className="w-100">
                            <button
                              href="/local-de-voto"
                              disabled={loading || !goodToGo}
                              className="p-3 d-flex align-items-center gap-3 justify-content-center local-voto-btn w-100 border-0"
                            >
                              <span>Consultar</span>
                              {loading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                ></div>
                              ) : (
                                <IonIcon
                                  name="arrow-forward-outline"
                                  size="24"
                                />
                              )}
                            </button>
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                              size="invisible"
                            />
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                  <div className="w-100 local-voto-results position-relative">
                    {message && (
                      <Alert
                        onClose={() => setMessage(null)}
                        dismissible
                        variant={message.type}
                      >
                        {message.text}
                      </Alert>
                    )}
                    {result && (
                      <>
                        <div className="d-flex flex-column gap-3 h-100">
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-600">Nome</span>
                            <span className="text-capitalize">
                              {result?.nome}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-600">Data de Nascimento</span>
                            <span className="text-capitalize">
                              {result?.data_nascimento}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-600">Local de Voto</span>
                            <span className="text-capitalize">
                              {result?.local_voto}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-600">Mesa de Voto</span>
                            <span className="text-capitalize d-flex flex-column  ">
                              <span>{result?.mesa_voto}</span>
                              {result.latitude && result.longitude && (
                                <a
                                  href="#"
                                  className=" text-accent small d-inline"
                                  style={{
                                    textDecoration: "underline",
                                    width: "fit-content",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowMap(true);
                                    return false;
                                  }}
                                >
                                  ver localização no mapa
                                </a>
                              )}
                            </span>
                          </div>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-600">Concelho</span>
                            <span className="text-capitalize">
                              {result?.concelho}
                            </span>
                          </div>
                        </div>
                        {showMap && result.latitude && result.longitude && (
                          <div className="local-de-voto-map">
                            <LocalDeVotoMap
                              title={result?.mesa_voto}
                              description={result?.local_voto}
                              info={result?.pos_nome}
                              position={[result.latitude, result.longitude]}
                            />
                            <button
                              className="btn btn-sm bg-color-lighter component-radius shadow component-border p-2 fw-600"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowMap(false);
                                return;
                              }}
                              style={{
                                position: "absolute",
                                top: 0,
                                zIndex: 1001,
                                right: 0,
                                margin: "10px",
                              }}
                            >
                              <IonIcon name="close" size="18" />
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <a
                href="#"
                className="mt-5 theme-toggle d-block "
                style={{
                  color: "var(--body-color)",
                  zIndex: 10,
                  fontSize: 18,
                  position: "absolute",
                  right: 30,
                  top: 20,
                }}
                onClick={() => {
                  dispatch(setTheme(theme === "light" ? "dark" : "light"));
                }}
              >
                <div style={{}}>
                  <IonIcon
                    className="ml-0"
                    name={theme === "light" ? "sunny-outline" : "moon-outline"}
                    size="24"
                  />
                </div>
              </a>

              <Footer></Footer>
            </div>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default connect((state) => ({ ...state }))(WaitingPage);
