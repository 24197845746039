import React, { useEffect, useState } from "react";
import Config from "config";
import {
  Row,
  Col,
  Navbar,
  Nav,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import { NavLink } from "react-router-dom";
import IonIcon from "components/Icon";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom";
import { format, formatHumanReadable } from "utils";
import Skeleton from "react-loading-skeleton";

const NavComponent = (props) => {
  const { elections, regions, collapse } = props;

  const location = useLocation();

  const { selected, update, theme } = elections;

  const { context } = Config.defaults;

  const selectedItem = regions.data.filter((r) => r.code == selected)[0];

  const selectedName = selectedItem ? selectedItem.name : selected;

  const contextKeys = Object.keys(context);

  const navigationPrefix = props.navigationPrefix || "";

  return (
    <>
      <Row
        className={`border-transparent-2  mx-0 position-relative pt-2 pt-lg-4 ${
          props.size === "sm" ? "d-lg-none position-fixed w-100 " : ""
        } `}
        style={{
          ...(props.size === "sm"
            ? {
                top: 0,
                left: 0,
                zIndex: 1005,
                background: "var(--bg-color)",
                boxShadow: "var(--component-shadow)",
              }
            : {}),
        }}
      >
        <Col
          className="d-flex flex-column justify-content-end pl-lg-2 "
          sm="12"
        >
          {props.size !== "sm" && (
            <a
              href="/"
              className="text-reset mb-2"
              style={{ width: "fit-content" }}
            >
              <img
                className="mb-0 d-xl-none"
                src={`/images/logo-sm-${theme}.svg`}
                style={{ width: 45 }}
              />
            </a>
          )}
          <div
            className={`d-flex gap-2 align-items-center   cursor-pointer ${
              props.size !== "sm" ? "mb-3" : ""
            }`}
            style={{ width: "fit-content" }}
            onClick={collapse}
          >
            <div className="d-xl-none ml-xl-n3">
              <IonIcon name={"menu"} size="30" color="var(--color-accent)" />
            </div>
            <div>
              <h1
                className="text-uppercase selected-region-title ml-lg-3 mb-0"
                style={{
                  borderBottom: "2px solid var(--active-region-text-color)",
                  width: "fit-content",
                  color: "var(--body-color)",
                }}
              >
                {location.pathname === `/mesas${navigationPrefix}`
                  ? "Resultados por Mesas"
                  : selectedName}
              </h1>
            </div>
          </div>
          <div className="w-100 d-flex align-items-end  app-context-col">
            <Nav
              variant="pills"
              defaultActiveKey={contextKeys[0]}
              className="ml-xl-3 flex-nowrap context-nav-tabs"
            >
              {contextKeys.map((c) => (
                <Nav.Item
                  key={`context-${c}`}
                  className={props.size === "sm" ? "pt-3 pt-lg-0" : ""}
                >
                  <NavLink
                    className={`app-menu-item nav-link body-color p-3 t-c-${c}`}
                    to={`/${Config.dashURL + navigationPrefix}/${
                      selected || "nacional"
                    }/${c}`}
                  >
                    {context[c].label}
                  </NavLink>
                </Nav.Item>
              ))}
              <Nav.Item className={props.size === "sm" ? "pt-3 pt-lg-0" : ""}>
                <NavLink
                  to={`/${Config.dashURL + navigationPrefix}/${
                    selected || "nacional"
                  }/resumo`}
                  className="app-menu-item nav-link body-color p-3 t-c-resumo "
                >
                  Resumo
                </NavLink>
              </Nav.Item>
              <Nav.Item className={props.size === "sm" ? "pt-3 pt-lg-0" : ""}>
                <NavLink
                  to={`/${Config.mapURL + navigationPrefix}`}
                  className="app-menu-item nav-link text-center p-3 d-inline-block t-c-mesas"
                  style={{ color: "#4368A7" }}
                >
                  <span className="ml-1">Mesas</span>
                </NavLink>
              </Nav.Item>
            </Nav>

            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  <div className=" text-left">
                    Resultados Provisórios,<br></br>
                    <span className="">
                      Atualizado{" "}
                      <b>
                        {formatHumanReadable({
                          date: update.date ?? "",
                          time: update.time ?? "",
                        })}
                      </b>
                    </span>
                  </div>
                </Tooltip>
              }
            >
              <span
                className="d-md-none ml-auto cursor-pointer ml-auto mb-3 text-right"
                style={{
                  position: "absolute",
                  top: 6,
                  right: 15,
                }}
              >
                <IonIcon name="information-circle-outline" size="24" />
              </span>
            </OverlayTrigger>
            <div
              className="cursor-pointer fs-12 ml-auto mb-3 text-right d-none d-md-block"
              style={{ lineHeight: 1.2 }}
            >
              <OverlayTrigger
                placement="top"
                style={{ zIndex: 150000 }}
                overlay={
                  <Tooltip className="small">
                    {update.date}, {update.time} <br></br>de Cabo Verde
                  </Tooltip>
                }
              >
                <div className="d-flex gap-2 ">
                  <div className="flex-column align-items-right d-md-flex">
                    <span className="font-weight-500 d-none d-sm-inline-block ">
                      Resultados Provisórios
                    </span>

                    <span className="">
                      Atualizado{" "}
                      <b>
                        {formatHumanReadable({
                          date: update.date ?? "",
                          time: update.time ?? "",
                        })}
                      </b>
                    </span>
                  </div>
                  <div className="">
                    <IonIcon name="information-circle-outline" size="24" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const AppNav = (props) => {
  const [smallNav, setSmallNav] = useState(false);

  const toggleSmallNav = (e) => {
    e.preventDefault();

    setSmallNav(
      window.scrollY >
        document.querySelector(".app-header-wrapper").getBoundingClientRect()
          .height -
          50
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleSmallNav);

    return () => window.removeEventListener("scroll", toggleSmallNav);
  }, []);
  return (
    <>
      {!props.hideResponsiveMenu && smallNav && (
        <NavComponent {...props} size="sm" />
      )}
      <NavComponent {...props} />
    </>
  );
};
export default connect((state) => ({ ...state }))(AppNav);
