import IonIcon from "components/Icon";
import React from "react";
import {
  Accordion,
  Card,
  Button,
  NavLink,
  Navbar,
  OverlayTrigger,
  Tooltip,
  Nav,
  NavbarBrand,
} from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { connect, useDispatch } from "react-redux";
import {
  Link,
  useParams,
  withRouter,
} from "react-router-dom/cjs/react-router-dom";
import { fetchData, setTheme } from "store/elections/actions";

import cfg from "../../../config";

const ClosedToolip = () => (
  <OverlayTrigger
    placement="top"
    style={{ zIndex: 150000 }}
    overlay={<Tooltip className="small">100% das mesas apuradas</Tooltip>}
  >
    <span
      className=" ml-auto position-absolute  right-0 align-items-center justify-content-center  blinking-text"
      style={{
        marginRight: 10,
        width: 16,
        height: 16,
        top: "50%",
        marginTop: -8,
      }}
    >
      <IonIcon name="checkmark-outline" size="16" />
    </span>
  </OverlayTrigger>
);

const Side = (props) => {
  const dispatch = useDispatch();

  const { data, isFetching } = props.regions;
  const parents = data.filter((d) => d.parent_code === "");
  const navClss = props.visible ? "sidebar-visible" : "sidebar-hidden";
  const fetchData = props.fetchData;
  const { theme, selected, update, closedCircles, selectedContext } =
    props.elections;

  const isClosed = (code) => {
    const circulo = closedCircles.filter((m) => m.key == code)[0];
    return circulo && circulo.pct ? circulo.pct == 100 : false;
  };

  const navigationPrefix = props.navigationPrefix || "";

  return (
    <Navbar.Collapse
      id="sidebarMenu"
      className={`${navClss} d-block sidebar small pr-3 overflow-hidden component-shadow pb-5`}
      style={{ paddingTop: 30 }}
    >
      <Navbar.Brand href="/" className="logo-lg">
        <img
          src={`/images/logo-${theme}.svg`}
          style={{ minWidth: "150px" }}
          alt="Eleições Autárquicas 2024"
        />
      </Navbar.Brand>

      <OverlayTrigger
        placement="top"
        style={{ zIndex: 150000 }}
        overlay={
          <Tooltip className="small">
            {props.visible ? "Desafixar" : "Fixar "} menu lateral
          </Tooltip>
        }
      >
        <div
          className="custom-control custom-switch d-none d-xl-block  my-xl-5"
          style={{ width: "fit-content" }}
        >
          <input
            type="checkbox"
            className="custom-control-input"
            id="toggle-menu"
            checked={props.visible}
            onChange={() => {
              props.handleCollpase();
            }}
          />
          <label className="custom-control-label" htmlFor="toggle-menu"></label>
        </div>
      </OverlayTrigger>

      {isFetching ? (
        <SkeletonTheme
          color="var(--skeleton-bg)"
          highlightColor="var(--skeleton-highlight-bg)"
        >
          <Skeleton
            count={10}
            height={18}
            className="component-radius"
          ></Skeleton>
        </SkeletonTheme>
      ) : (
        <Accordion className="pt-4 pt-xl-0">
          {parents.map((r, i) => {
            const childs = data.filter(
              (d) => d.parent_code === r.code && d.code != r.code
            );
            const hasChilds = childs.length ? true : false;
            return (
              <div
                className="app-region-item position-relative"
                key={`parent-${r.code}-${i}`}
              >
                <Accordion.Toggle
                  as={!hasChilds ? Link : "div"}
                  variant="link"
                  eventKey={r.code}
                  className={`text-left w-100 body-color d-flex align-items-center nav-link cursor-pointer ${
                    r.code === selected ? "active" : ""
                  } t-c-${r.code}`}
                  to={
                    !hasChilds
                      ? `/${cfg.dashURL + navigationPrefix}/${r.code}/${
                          selectedContext || "camara"
                        }`
                      : null
                  }
                >
                  <img
                    className="app-region-img"
                    src={`/images/circulos/${r.code}.png`}
                    alt={r.name}
                  />
                  <span className="app-region-name text-truncate d-inline-block">
                    {r.name?.toLowerCase()}
                  </span>
                  {hasChilds && (
                    <span
                      className="shadow p-2 child-toggler ml-auto bg-component body-color d-flex align-items-center justify-content-center "
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: "100%",
                      }}
                    >
                      <i
                        className="fas fa-chevron-right small  "
                        style={{ fontSize: 8 }}
                      ></i>
                    </span>
                  )}
                  {!hasChilds && isClosed(r.code) ? <ClosedToolip /> : <></>}
                </Accordion.Toggle>
                {hasChilds ? (
                  <>
                    <Accordion.Collapse eventKey={r.code}>
                      <div className="pl-4 cl">
                        {childs.map((c) => (
                          <Link
                            key={c.code}
                            className={`position-relative  d-block text-left  app-region-child text-uppercase body-color nav-link ${
                              c.code === selected ? "active" : ""
                            }`}
                            to={`/${cfg.dashURL + navigationPrefix}/${c.code}/${
                              selectedContext || "camara"
                            }`}
                            variant="link"
                          >
                            <span
                              className="ml-2 d-block pr-4 text-capitalize app-region-name"
                              style={{ fontSize: 13 }}
                            >
                              {c.name?.toLowerCase()}
                            </span>
                            {isClosed(c.code) && <ClosedToolip />}
                          </Link>
                        ))}
                      </div>
                    </Accordion.Collapse>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </Accordion>
      )}

      <OverlayTrigger
        placement="top"
        style={{ zIndex: 150000 }}
        overlay={<Tooltip className="small">Mudar tema</Tooltip>}
      >
        <a
          href="#"
          className="mt-3 mt-lg-5 theme-toggle d-block "
          style={{
            color: "var(--body-color)",
            fontSize: 18,
            width: "fit-content",
          }}
          onClick={() => {
            dispatch(
              setTheme(props.elections.theme === "light" ? "dark" : "light")
            );
          }}
        >
          <IonIcon
            className="ml-0"
            name={theme === "light" ? "sunny-outline" : "moon-outline"}
            size="24"
          />
        </a>
      </OverlayTrigger>
    </Navbar.Collapse>
  );
};

const mapStateToProps = (s) => ({ ...s });

const mapDispatchToProps = (dispatch, props) => {
  return {
    fetchData: (code, version) => {
      const _r = dispatch(fetchData(code, version));

      _r.then(() => {
        props.afterFetch();
      });

      return _r;
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Side);
