import React, { useEffect } from "react";

import _ from "components/Translate";

import { connect, useDispatch } from "react-redux";

import Config from "config";

import DashboardView from "components/Dashboard";

import ResumoView from "components/Charts/Resumo";

import { Switch, Route, Redirect } from "react-router-dom";

import { Container } from "react-bootstrap";

const DataRouter = ({ prefix }) => {
  const dispatch = useDispatch();

  return (
    <Route
      path={`/${Config.dashURL + prefix}/:regionCode/:context`}
      exact
      render={({ match }) => {
        const { regionCode, context } = match.params;
        return context === "resumo" ? (
          <ResumoView
            selectedContext={context?.toLowerCase()}
            regionCode={regionCode?.toLowerCase()}
          />
        ) : context === "camara" || context === "assembleia" ? (
          <DashboardView
            navigationPrefix={prefix}
            selectedContext={context?.toLowerCase()}
            regionCode={regionCode?.toLowerCase()}
          />
        ) : (
          <Redirect to={`/${Config.dashURL + prefix}/nacional/camara`} />
        );
      }}
    />
  );
};

const Dashboard = ({ prefix = "" }) => {
  return (
    <Container fluid>
      <Switch>
        <Route
          path={`/${Config.dashURL + prefix}/:regionCode`}
          exact
          render={({ match }) => {
            const { regionCode } = match.params;
            console.log(regionCode);
            return (
              <Redirect
                to={`/${Config.dashURL + prefix}/${regionCode}/camara`}
              />
            );
          }}
        />
        <DataRouter prefix={prefix} />
      </Switch>
    </Container>
  );
};

const mapState = (s) => ({ ...s });

export default connect(mapState)(Dashboard);
