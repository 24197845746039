import React, { Component } from "react";
import { Row, Col, Table, Modal } from "react-bootstrap";
import { format, ReplaceSpecialChars, uid, imgPath } from "utils";

import _ from "components/Translate";
import IonIcon from "components/Icon";

class ResultadosZonas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.showArea,
      zonas: this.props.zonas,
      searchQuery: this.props.selectedZona,
    };
  }

  hideModal = () => {
    this.setState({ show: false });
    this.props.resetArea();
  };

  handleSearchChange = (q) => {
    this.setState((v) => ({
      ...v,
      searchQuery: q,
    }));
  };

  getCandidato = (id) => {
    return this.props.candidatos.filter((item) => item.id === id)[0] || {};
  };

  resize() {
    //const tableHeight =
  }

  componentDidMount() {
    this.resize();

    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }
  render() {
    let fristRow = null;

    if (this.state.zonas && this.state.zonas[0] && this.state.zonas[0].votos)
      fristRow = this.state.zonas[0].votos;

    let zonas = this.props.zonas
      ?.slice(1)
      ?.filter((item) =>
        this.state.searchQuery
          ? ReplaceSpecialChars(item.nome).includes(
              ReplaceSpecialChars(this.state.searchQuery)
            )
          : true
      );

    return (
      <>
        <Modal
          size="lg"
          show={this.state.show}
          onHide={() => this.hideModal(false)}
          className="modal-zonas"
        >
          <Modal.Header
            className="border-0 position-sticky top-0 bg-color-lighter"
            style={{ zIndex: 10 }}
          >
            <div className="modal-title w-100  d-flex  gap-">
              <Row className="w-100">
                <Col lg="5" md="12">
                  <div
                    style={{ lineHeight: "20px" }}
                    className="mb-0  h6 modal-title-holder d-flex flex-column"
                  >
                    <span className="candidates text-capitalize body-color-lighter fw-500">
                      {_(this.props.context.toUpperCase())}
                    </span>
                    <span className="fw-600">Resultados por zonas </span>
                    <div className="text-uppercase regions  body-color">
                      {this.props.regions}
                    </div>
                  </div>
                </Col>
                <Col lg="7" md="12" className="mt-3 mt-lg-0">
                  <div className="position-relative d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control form-control-sm pr-4 zonas-search-control"
                      value={this.state.searchQuery}
                      onChange={(e) => this.handleSearchChange(e.target.value)}
                      placeholder={"Pesquisar Zona..."}
                    />

                    <IonIcon
                      name={
                        !this.state.searchQuery
                          ? "search-outline"
                          : "close-outline"
                      }
                      size="18"
                      color="#717171"
                      className="position-absolute right-0 mr-2"
                      onClick={() => {
                        if (this.state.searchQuery) {
                          this.handleSearchChange("");
                          const input = document.querySelector(
                            ".zonas-search-control"
                          );
                          if (input) input.focus();
                        }
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <div
                className="px-2 position-absolute cursor-pointer"
                style={{ top: 12, right: 0 }}
                onClick={() => this.hideModal(false)}
              >
                <IonIcon name="close-outline" size="30" />
              </div>
            </div>
          </Modal.Header>
          <div className="scrollable-fade d-md-none"></div>
          <Modal.Body
            context={this.props.context}
            region-selected={this.props.selectedCode}
            className="app-data-wrapper body-color pt-0"
          >
            <div
              className="table-responsive"
              style={{
                maxHeight: "calc(100vh - 220px)",
              }}
            >
              <Table className="border-0 m-0 mt-3 body-color" size="sm">
                <thead
                  className="pb-3 position-sticky top-0 bg-color"
                  style={{ zIndex: 1 }}
                >
                  <tr>
                    <th
                      className="border-0 p-2 pb-4 mxw-50 align-middle"
                      style={{
                        position: "sticky",
                        left: 0,
                        background: "var(--bg-color)",
                        minWidth: 190,
                      }}
                    >
                      <div className="icons-legend d-flex">
                        <div className="mr-3 mt-2">
                          <span className="d-block small">{" < 50% "}</span>
                          <img
                            className="icon-legend ml-1"
                            src="/images/icons/1.svg"
                            alt=""
                          />
                        </div>

                        <div className="mx-3 mt-2">
                          <span className="d-block small">{"> 50%"}</span>
                          <img
                            className="icon-legend ml-1"
                            src="/images/icons/2.svg"
                            alt=""
                          />
                        </div>

                        <div className="ml-3 mt-2">
                          <span className="d-block small"> 100% </span>
                          <img
                            className="icon-legend ml-1"
                            src="/images/icons/0.svg"
                            alt=""
                          />
                        </div>
                      </div>
                      <span className="small fw-600 pt-3 d-block">
                        Mesas Apuradas
                      </span>
                    </th>
                    {fristRow &&
                      fristRow.map((c) => {
                        return (
                          <th
                            party-id={c.id}
                            className="border-0 text-center p-2 pb-4 small align-middle"
                            key={uid()}
                          >
                            <div className="text-center app-voto-candidato d-block mb-2 mx-auto">
                              <img
                                src={imgPath(this.getCandidato(c.id).image)}
                                alt=""
                              />
                            </div>
                            <span className="candidates d-block fw-500">
                              {c.id}
                            </span>
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {zonas.map((z) => {
                    return (
                      <tr key={uid()} className="modal-item-shadow">
                        <td
                          className=" mxw-50 border-0 p-2 small align-middle "
                          style={{
                            position: "sticky",
                            left: 0,
                            background: "var(--bg-color)",
                          }}
                        >
                          <img
                            className="icon-legend td-con position-absolute"
                            src={`/images/icons/${z.estado}.svg`}
                            alt=""
                          />
                          <div className="d-block pl-4 text-uppercase">
                            {z.nome}
                          </div>
                        </td>
                        {fristRow &&
                          fristRow.map((zv) => {
                            const zona = z.votos.filter(
                              (zp) => zp.id === zv.id
                            )[0];

                            return (
                              <td
                                party-id={zona?.id}
                                className="border-0 text-center p-2 align-middle holder-highlight"
                                key={uid()}
                              >
                                {zona && (
                                  <>
                                    {zona.highlight === 1 && (
                                      <span
                                        highlight={zona.id}
                                        className="px-2 py-1 area-highlight text-nowrap rounded text-white font-weight-bold d-inline-block"
                                        style={{
                                          minWidth: "50px",
                                          background: `${
                                            this.getCandidato(zv.id).color
                                          }`,
                                        }}
                                      >
                                        {format(zona.value)}
                                      </span>
                                    )}
                                    {zona.highlight === 0 && (
                                      <span className="px-2 py-1 text-nowrap">
                                        {format(zona.value)}
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                            );
                          })}
                      </tr>
                    );
                  })}
                  {!zonas[0] && (
                    <tr>
                      <td colspan="2" className="small">
                        Zona não econtrada...
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ResultadosZonas;
