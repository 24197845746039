import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { LeftComponent } from "components/Countdown";
import moment from "moment-timezone";

const CountDownPage = ({ countdown, tick, fetchTime, elections }) => {
  const { isAffluenceDay } = countdown;

  return (
    <Container fluid afluence-urnes={isAffluenceDay ? "true" : "false"}>
      <Row>
        <Col className="left-countdown body-color" theme={elections.theme}>
          <LeftComponent />
        </Col>
      </Row>
    </Container>
  );
};

export default connect((s) => ({ ...s }))(CountDownPage);
