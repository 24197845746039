import React, { useEffect } from "react";
import Theme from "./components/Theme";
import Config from "config";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import Countdown from "pages/countdown";
import Dashboard from "pages/dashboard";
import LocalDeVoto from "pages/local-de-voto";
import MapView from "pages/map";
import NotFound from "pages/404";
import Unavailable from "pages/503";
import { connect, useDispatch } from "react-redux";
import { fetchTime, tick } from "store/countdown/actions";
import { getRegions } from "store/regions/actions";
import { fetchVersion, setTheme } from "store/elections/actions";
import HomePage from "pages/home";
import _config from "config";
import Data from "components/Theme/Data";
import ManutencaoPage from "pages/manutencao";
import Loading from "components/Loading";
import GetVersionData from "components/Theme/Data/get-version";
import AppLoading from "components/AppLoading";
import countdown from "pages/countdown";

export const navigation_uuid =
  "-" + process?.env?.REACT_APP_NAVIGATION_UUID ||
  "-704dd6db-50a7-474a-el248cd2-b95e708e7c03";

const Routes = (props) => {
  const { isElectionDay, lastUpdate } = props.countdown;

  const dispatch = useDispatch();

  const enableDashBoard =
    process?.env?.REACT_APP_ENABLE_DASHBOARD === "true" || false;
  const valid = isElectionDay || enableDashBoard;
  const siteStatus = props.elections?.update?.status_site || "-1";

  useEffect(() => {
    dispatch(fetchVersion(null, false));
    dispatch(getRegions());
  }, []);

  return (
    <>
      {props.elections?.update?.status_site ? (
        <>
          {siteStatus === "-1" ? (
            <GetVersionData update={false}>
              <ManutencaoPage />
            </GetVersionData>
          ) : (
            <Switch>
              {/* pesquisa local de voto */}
              <Route
                path="/local-de-voto"
                exact
                render={(props) => <LocalDeVoto />}
              />

              {/*ADMIN  Página de mesas / mapa*/}
              <Route
                path={`/${Config.mapURL + navigation_uuid}`}
                exact
                render={(props) => {
                  return (
                    <Theme
                      navigationPrefix={navigation_uuid}
                      showSidebar={true}
                      showNavDetails={false}
                      hideResponsiveMenu
                    >
                      <MapView></MapView>
                    </Theme>
                  );
                }}
              />

              {/* Página de mesas / mapa*/}
              <Route
                path={`/${Config.mapURL}`}
                exact
                render={(props) => {
                  if (valid)
                    return (
                      <Theme
                        showSidebar={true}
                        showNavDetails={false}
                        hideResponsiveMenu
                      >
                        <MapView></MapView>
                      </Theme>
                    );
                  else return <Redirect to="/"></Redirect>;
                }}
              />

              {/* Página de resultados  */}
              <Route
                path={`/${Config.dashURL + navigation_uuid}`}
                exact
                render={() => {
                  return (
                    <Redirect
                      to={`/${
                        Config.dashURL + navigation_uuid
                      }/nacional/camara`}
                    />
                  );
                }}
              />
              <Route
                path={`/${Config.dashURL}`}
                exact
                render={() => {
                  return <Redirect to={`/${Config.dashURL}/nacional/camara`} />;
                }}
              />
              <Route
                path={`/${Config.dashURL + navigation_uuid}`}
                render={() => {
                  return (
                    <Theme navigationPrefix={navigation_uuid}>
                      <Dashboard prefix={navigation_uuid} />
                    </Theme>
                  );
                }}
              />
              <Route
                path={`/${Config.dashURL}`}
                render={() => {
                  if (valid)
                    return (
                      <Theme>
                        <Dashboard />
                      </Theme>
                    );
                  else return <Redirect to="/"></Redirect>;
                }}
              />

              {/* Página inicial*/}
              <Route
                path="/"
                exact
                render={() => {
                  if (!isElectionDay) return <HomePage />;
                  else return <Redirect to={`/${Config.dashURL}`}></Redirect>;
                }}
              />

              <Route>
                <NotFound></NotFound>
              </Route>

              <Route>
                <Unavailable></Unavailable>
              </Route>
            </Switch>
          )}
        </>
      ) : (
        <>
          <AppLoading />
        </>
      )}
    </>
  );
};
export default connect((s) => ({ ...s }))(Routes);
