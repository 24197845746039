import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { format } from 'utils'
import _ from '../Translate';

/*const data = {
    M : {
        name : "Masculino",
        pct : 45
    },
    F : {
        name : "Feminino",
        pct : 55
    }
};*/

const style = {
    height:15, 
    borderRadius:'8px',
};

export default ({data})=>{
    
    const m_color = '#019dda';
    const f_color = 'pink';

    const F = data && data[0] ? data.filter( item=> item.code == "F")[0] : false

    const M = data && data[0] ? data.filter( item=> item.code == "M")[0] : false;

    return F && M ? (
       <Card className="shadow border-0 app-genero position-relative  mb-4" >
           <Card.Body>
                <h6 className="card-title">{_("GENERO_TITLE")}</h6>
                <div className="d-flex mb-2 mt-5" >
                    <span>Masculino</span>
                    <span className="ml-auto text-right">Feminino</span>
                </div>
                <div className="d-flex" style={ {  } }>
                    <div style={ { ...style, width: M.pct+'%', background:m_color, borderTopRightRadius:0, borderBottomRightRadius:0 } }></div>
                    <div style={ { ...style, width: F.pct+'%',background:f_color, borderTopLeftRadius:0, borderBottomLeftRadius:0  } }></div>
                </div>
                <div className="d-flex mt-2 mb-4" >
                    <span>{M.pct}%</span>
                    <span className="ml-auto text-right">{F.pct}%</span>
                </div>
           </Card.Body>
       </Card>
    ) : (
        <></>
    )
}