import React from "react";
import { Row, Col } from "react-bootstrap";
const Footer = () => {
  return (
    <div className="app-footer px-2 pb-3 d-flex flex-column flex-sm-row items-center gap-3 mt-5">
      <div className="mt-4 mb-sm-2 text-center text-sm-left">
        <img src="/images/logo-dgape.png" alt="DGAPE" />
      </div>
      <div className="mt-4 mb-sm-2 text-center text-md-left">
        <img src="/images/logo-cne.png" alt="CNE" />
      </div>
      <div className="text-center text-sm-right mt-4 mb-sm-2 d-flex flex-column flex-lg-row align-items-center ml-sm-auto">
        <span className="poweredby d-block d-sm-inline-block text-muted mr-2 text-uppercase font-weight-bold small">
          Powered by
        </span>
        <img
          className="img-poweredby d-inline-block"
          src="/images/logo-nosi.svg"
          alt="NOSi EPE"
        />
      </div>
    </div>
  );
};

export default Footer;
