import { SET_DEPUTADOS, GET_DEPUTADOS } from "./action-type";

const initialState = [];

export default (state = initialState, action) => {
  if (action.type === SET_DEPUTADOS) {
    return [...action.data];
  }

  if (action.type === GET_DEPUTADOS) {
    return [...action.data];
  }

  return state;
};
