import React from "react";
import { connect } from "react-redux";
import Config from "../../config";
function AppLoading({ elections }) {
  const { theme } = elections;
  return (
    <div
      id="app-theme"
      theme={theme}
      className="d-flex align-items-center justify-content-center flex-column gap-2"
      style={{
        height: "100%",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <img alt={Config.site.name} src={`/images/logo-${theme}.svg`} />

      <div
        className="spinner-border spinner-border-sm text-accent"
        role="status"
      ></div>
    </div>
  );
}

export default connect((s) => ({ ...s }))(AppLoading);
