import Footer from "components/Theme/Footer";
import config from "config";
import React, { useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";

import Map from "./App/App";
import IonIcon from "components/Icon";

export default () => {
  const goUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    goUp();
  }, []);
  return (
    <Container fluid className="position-relative">
      <Row className="position-absolute w-100 left-0 ">
        <Col className="mb-3">
          <Card className="shadow border-0 bg-transparent component-radius mr-3">
            <Card.Body className="p-0">
              {!config.gis.show_igrpapp && <Map />}
            </Card.Body>
          </Card>
          <div
            onClick={goUp}
            className="bg-color shadow map-go-up d-flex align-items-center justify-content-center d-lg-none"
          >
            <IonIcon
              name="arrow-up-outline"
              size="22"
              color="var(--body-color)"
            />
          </div>
          <Footer></Footer>
        </Col>
      </Row>
    </Container>
  );
};
