import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import _ from "components/Translate";

import { ZeroPad } from "utils";
import Config from "config";
import { setTime, fetchTime, tick } from "store/countdown/actions";

import moment from "moment-timezone";
import "moment/locale/pt";

moment.locale("pt");

const SplitString = (str, s, e) => {
  return ZeroPad(str).toString().slice(s, e);
};

const Countdown = ({ countdown, fetchTime, tick }) => {
  const { fetched, isElectionDay, isAffluenceDay, electionDate, currentDate } =
    countdown;

  const removeListener = () => {
    window.removeEventListener("focus", fetchTime);
  };

  useEffect(() => {
    fetchTime();
    return () => removeListener();
  }, []);

  useEffect(() => {
    let interval;

    if (!isElectionDay) {
      const start_time = performance.now();
      interval = setTimeout(() => {
        fetchTime();
      }, 60000);
    } else {
      removeListener();
    }

    return () => clearTimeout(interval);
  }, [countdown.currentDate]);

  let currDate = new Date(currentDate);

  const difference = electionDate - currDate.getTime();

  let clock = {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };

  const timerComponents = Object.keys(clock);

  const lenght = Object.keys(clock).length;

  const GetDay = () => {
    const date = Config?.dates?.showElection;

    if (!date) return <div>Eleição Autárquicas 2024</div>;

    const monthName = moment().month(date.month).format("MMMM");

    return (
      <div className="election-day p-3">
        <b>{date.day}</b> de&nbsp;
        <b className="text-capitalize">{monthName}</b>&nbsp;
        <b>de {date.year}</b>
      </div>
    );
  };

  const GetHours = () => {
    return (
      <div className="mb-3 py-2 px-4" style={{ lineHeight: "25px" }}>
        <small>
          Acompanhe os <b>resultados provisórios</b> a partir das{" "}
        </small>
        <b className="h3 d-block m-0">
          {Config.dates.showElection.hours} horas
        </b>{" "}
        <small>de Cabo Verde</small>
      </div>
    );
  };

  const GetVotePlace = () => {
    return (
      <div className="local-voto mt-3 mt-md-5">
        {Config.local_de_voto.show && (
          <Link to="/local-de-voto" className="p-3 ">
            Consulte seu <b>Local de Voto</b>{" "}
            <i className="fas fa-chevron-right small ml-2"></i>
          </Link>
        )}
      </div>
    );
  };

  return (
    <div className="countdown text-center body-color d-flex w-100 text-center justify-content-center align-items-center">
      <div className="logo mb-2 mb-md-4">
        <img
          src="/images/logo-light.svg"
          alt="Eleições Autárquicas 2024"
          style={{ width: 240, maxWidth: "100%" }}
        />
      </div>

      <GetDay />

      {
        fetched && (
          <>
            <GetHours />

            <GetVotePlace />
          </>
        )
        /*fetched && (
                    <>
                    <div className="time-countdown mt-2 mb-4">
                        {
                            timerComponents.map((interval, i) => {
                                let str =  lenght > (i+1) ? ':' : '';
                                let mrdesc = interval === 'seconds' ? 2 : 3;
                                return(
                                    <div className="d-inline-block" key={i}>
                                        <div className={`mr1 pl-1 ${interval}`}>
                                            <span className="mr-1">{SplitString(clock[interval], 0, 1)}</span>
                                            <span className="mr-1">{SplitString(clock[interval], 1, 2)}</span>
                                        {str}
                                    </div>
                                        <div className={`desc desc-${interval} mr-${mrdesc}`}>{_(interval.toUpperCase())}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="local-voto mt-3 mt-md-5">
                        {Config.local_de_voto.show && (
                            <Link to="/local-de-voto" className="p-2 p-md-3" >Ver Local de Voto</Link>
                        )}
                    </div>
                    </>
                )*/
      }
      {!fetched && (
        <div className="text-center">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      <div className="logo-footer mt-5 mb-3 position-absolute">
        <div className="d-none d-md-block text-center">
          <img
            src="/images/logo-dgape.png"
            className="mr-4 mr-md-5 d-inline-block"
            alt="DGAPE"
          />
          <img
            src="/images/logo-cne.png"
            className="d-inline-block"
            alt="CNE"
          />

          {!isAffluenceDay && (
            <div className="text-right d-inline-block ml-4 ml-md-5">
              <span className="poweredby d-inline-block text-muted mr-2 text-uppercase font-weight-bold">
                Powered by
              </span>
              <img
                className="img-poweredby d-inline-block"
                src="/images/logo-nosi.svg"
                alt="NOSi EPE"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  (s) => ({ ...s }),
  (dispatch) => ({
    fetchTime: () => dispatch(fetchTime()),
    tick: (start) => dispatch(tick(start)),
  })
)(Countdown);
