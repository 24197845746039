import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default () => {
  return (
    <Container fluid id="app-theme" theme="light">
      <Row>
        <Col
          className="left-countdown text-white d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="text-center">
            <img src="/images/logo-light.svg" alt="Eleições Autárquicas 2024" />

            <h5 className="mt-4">Página não encontrada...</h5>

            <div className="mt-4">
              <Link
                to="/"
                className="btn bg-accent component-shadow component-radius component-border border py-2 px-3"
              >
                <i className="fas fa-chevron-left small"></i> Voltar à página
                principal
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
