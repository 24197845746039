import React, { Component, useState } from "react";
import _ from "../Translate";
import CloneDeep from "lodash/cloneDeep";
import {
  Modal,
  Col,
  Row,
  Dropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  UpperToCapitalize,
  ZeroPad,
  ReplaceSpecialChars,
  uid,
  imgPath,
} from "utils";
import { useDispatch } from "react-redux";
import { fetchData, setRegion } from "store/elections/actions";
import IonIcon from "components/Icon";

export const ModalElected = ({
  id,
  selectedRegion,
  regions,
  context,
  onHide,
  elected,
  partidos,
}) => {
  const [searchVal, setSearchVal] = useState("");

  const [partidoFilter, setPartidoFilter] = useState("");

  const [regionFilter, setRegionFilter] = useState("");

  const results = elected.filter((item) => {
    return (
      ReplaceSpecialChars(item?.name).includes(
        ReplaceSpecialChars(searchVal)
      ) &&
      (partidoFilter ? item?.partido === partidoFilter : true) &&
      (regionFilter ? item.circulo === regionFilter : true)
    );
  });

  const partidosPorRegiao = elected
    .filter((item) => (regionFilter ? item.circulo === regionFilter : true))
    .map((item) => item.partido);

  const selectedPartido = id ? partidos.find((c) => c.id === id) : null;

  return (
    <Modal
      size="lg"
      show={true}
      onHide={() => onHide(false)}
      className="elected-modal"
    >
      <Modal.Header
        className="border-0 position-sticky top-0 bg-color-lighter component-shadow pr-5"
        style={{ zIndex: 10 }}
      >
        <div className="d-flex flex-column gap-2 w-100">
          <div className="modal-title w-100 d-flex  gap-2">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center w-100">
              <div className="w-100">
                {selectedPartido && (
                  <div className="d-flex align-items-center gap-2">
                    <span className="candidates font-weight-bold ">{id}</span>
                  </div>
                )}

                <div className="mb-0  h6 modal-title-holder">
                  <span className="font-weight-bold">{context}</span>
                  {selectedRegion?.code !== "nacional" && (
                    <div className="text-uppercase regions mt-1 body-color">
                      {selectedRegion?.name}
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-3 mt-lg-0" style={{ width: "fit-content" }}>
                <div className="position-relative d-flex align-items-center small gap-2">
                  {!id && (
                    <Form.Control
                      as="select"
                      className=""
                      onChange={(e) => setPartidoFilter(e.target?.value)}
                      style={{ minWidth: 115 }}
                    >
                      <option value="" className="text-italic">
                        Partidos
                      </option>
                      {[...new Set(partidosPorRegiao)]?.map((r) =>
                        r ? (
                          <option value={r} key={r}>
                            {r}
                          </option>
                        ) : null
                      )}
                    </Form.Control>
                  )}

                  {selectedRegion?.code === "nacional" && (
                    <Form.Control
                      as="select"
                      className="min-w-25"
                      onChange={(e) => {
                        setRegionFilter(e.target?.value);
                      }}
                      style={{ minWidth: 115 }}
                    >
                      <option value="" className="text-italic">
                        Círculos
                      </option>
                      {[
                        ...new Set(
                          elected.map((item) => {
                            return item.circulo;
                          })
                        ),
                      ]
                        ?.sort()
                        ?.map((r) => (
                          <option value={r} key={r}>
                            {r}
                          </option>
                        ))}
                    </Form.Control>
                  )}
                </div>
              </div>
            </div>
            <div
              className="px-2 position-absolute cursor-pointer"
              style={{ top: 12, right: 0 }}
              onClick={() => onHide(false)}
            >
              <IonIcon name="close-outline" size="30" />
            </div>
          </div>
          <div className="position-relative w-100 d-flex align-items-center">
            <input
              type="text"
              className="form-control form-control-sm pr-4 vereador-search-control "
              onKeyUp={(e) => setSearchVal(e.target.value)}
              placeholder={"Pesquisar..."}
              style={{ minWidth: 130 }}
            />
            <IonIcon
              name={!searchVal ? "search-outline" : "close-outline"}
              size="18"
              color="#717171"
              className="position-absolute right-0 mr-2 "
              onClick={() => {
                if (searchVal) {
                  setSearchVal("");
                  const input = document.querySelector(
                    ".vereador-search-control"
                  );
                  if (input) {
                    input.value = "";
                    input.focus();
                  }
                }
              }}
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex-column gap-2">
          <div className="">
            <div>
              {!results[0] && <div className="small">Nenhum resultado...</div>}
              {results.map((d, z) => {
                const partido =
                  partidos.filter((c) => c.id === d?.partido)[0] || {};
                return (
                  <div
                    className="p-2 modal-item-shadow component-border d-flex align-items-center gap-3 fs-14"
                    key={`data-${z}`}
                  >
                    <OverlayTrigger
                      placement="top"
                      trigger={["focus", "hover"]}
                      overlay={<Tooltip>{partido?.name}</Tooltip>}
                    >
                      <div
                        className="d-flex flex-column gap-1 align-items-md-center partido-icon-holder "
                        style={{
                          height: "fit-content",
                          minWidth: 50,
                          maxWidth: 50,
                        }}
                      >
                        <img
                          src={imgPath(partido.image)}
                          className="app-voto-candidato mx-auto"
                          alt={partido.id}
                        />

                        <span className="fw-500 body-color-light text-truncate text-center w-100">
                          {d.partido}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <span
                      className=" text-center font-weight-bold  d-none"
                      style={{ width: 40 }}
                    >
                      {ZeroPad(d.ordem)}
                    </span>
                    <img
                      src={imgPath(d.image)}
                      className="app-voto-candidato modal-img"
                      alt={partido.id}
                      style={{
                        width: 35,
                        height: 35,
                      }}
                    />
                    <div className="d-flex flex-column">
                      <div className="text-uppercase">{d.name}</div>
                      <div className="fw-500">{d.circulo}</div>
                    </div>
                    <div className="d-none test">{d.partido}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
      {/*
      <Modal.Body>
        {!results[0] && <div className="small">Nenhum resultado...</div>}
        {results.map((item, idx) => {
          const partido =
            partidos.filter((c) => c.id === item?.partido)[0] || {};

          return (
            <div
              key={uid()}
              className="position-relative mb-3 pt-3 holder-elected-candidates"
            >
              <div
                className="position-absolute order-candidato"
                circulo-active={id ? "partido" : "nacional"}
              >
                {!id && (
                  <>
                    <img
                      src={imgPath(partido.image)}
                      className="app-voto-candidato"
                      alt=""
                    />
                    <span className="align-middle ml-0 ml-md-2 mt-1 mt-md-0 font-weight-bold party-name d-block d-md-inline-block">
                      {item.partido}
                    </span>
                  </>
                )}
                {id && (
                  <span className="align-middle text-center font-weight-bold party-name d-block">
                    {ZeroPad(item.ordem)}
                  </span>
                )}
              </div>
              <div
                className="info-candidates d-flex"
                circulo-active={id ? "partido" : "nacional"}
              >
                <div className="app-elected-cadidate-pic">
                  <img className="" src={imgPath(item.image)} alt="" />
                </div>
                <div className="pl-3 w-100">
                  {item.biography && (
                    <a
                      className="text-left d-block m-0 p-0"
                      onClick={() => this.infoBoxClick(idx)}
                    >
                      <div className="info-box-candidates">
                        <span className="info-candidates-name d-block text-capitalize font-weight-normal fs-12">
                          {UpperToCapitalize(item.name)}{" "}
                          <i
                            className="fas fa-ellipsis-v ml-2 info-biography"
                            title="Biografia"
                          ></i>
                        </span>
                        <span className="info-candidates-circle d-block font-weight-bold fs-11">
                          {item.circulo}
                        </span>
                      </div>
                    </a>
                  )}

                  {!item.biography && (
                    <>
                      <span className="info-candidates-name d-block text-capitalize font-weight-normal fs-12">
                        {UpperToCapitalize(item.name)}
                      </span>
                      <span className="info-candidates-circle d-block font-weight-bold fs-11">
                        {item.circulo}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {item.biography && (
                <div>
                  <Row className="m-0 pt-3">
                    {item.profession && (
                      <Col md="6" xs="12">
                        <div className="small box-profession">
                          <span className="d-block font-weight-bold">
                            Profissão
                          </span>
                          <span className="d-block">{item.profession}</span>
                        </div>
                      </Col>
                    )}

                    {item.birth_date && (
                      <Col md="6" xs="12">
                        <div className="small box-birth_date mb-3 mb-md-0">
                          <span className="d-block font-weight-bold">
                            Data Nascimento
                          </span>
                          <span className="d-block">{item.birth_date}</span>
                        </div>
                      </Col>
                    )}

                    <Col xs="12">
                      <div className="text-justify small my-3">
                        {item.biography}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          );
        })}
      </Modal.Body>
  */}
    </Modal>
  );
};

export default ModalElected;
