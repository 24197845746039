import axios from "axios";
import Config from "config";

export const format = (x) =>
  x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0;

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const CallService = (p) => {
  let method = p.hasOwnProperty("method") ? p.method.toLowerCase() : "get",
    headers = {};

  if (p.hasOwnProperty("headers")) headers = p.headers;

  if (p.hasOwnProperty("data"))
    return axios[method](`${p.path}`, p.data, headers);
  else return axios[method](`${p.path}`, headers);
};

export const UpperToCapitalize = (s) => {
  if (typeof s !== "string") return "";

  return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
};

export const ZeroPad = (x) => {
  if (x < 10) {
    x = "0" + x;
  }

  return x;
};

export const ReplaceSpecialChars = (val = "") => {
  var chars = ["[", "]", "€", "«", "»", ";", "="];
  var accents = [
    {
      base: "a",
      accents: [
        "\u00c4",
        "\u00c3",
        "\u00e1",
        "\u00e0",
        "\u00e2",
        "\u00e3",
        "\u00e4",
        "\u00c1",
        "\u00c0",
        "\u00c2",
      ],
    },
    {
      base: "e",
      accents: [
        "\u00e9",
        "\u00e8",
        "\u00ea",
        "\u00ea",
        "\u00c9",
        "\u00c8",
        "\u00ca",
        "\u00cb",
      ],
    },
    {
      base: "i",
      accents: [
        "\u00ed",
        "\u00ec",
        "\u00ee",
        "\u00ef",
        "\u00cd",
        "\u00cc",
        "\u00ce",
        "\u00cf",
      ],
    },
    {
      base: "o",
      accents: [
        "\u00d6",
        "\u00d5",
        "\u00f3",
        "\u00f2",
        "\u00f4",
        "\u00f5",
        "\u00f6",
        "\u00d3",
        "\u00d2",
        "\u00d4",
      ],
    },
    {
      base: "u",
      accents: [
        "\u00fa",
        "\u00f9",
        "\u00fb",
        "\u00fc",
        "\u00da",
        "\u00d9",
        "\u00db",
      ],
    },
    { base: "c", accents: ["\u00e7", "\u00c7"] },
    { base: "n", accents: ["\u00f1", "\u00d1"] },
  ];

  //replace white spaces to _
  var str = val.replaceAll(" ", "_");
  //replace - to _
  str = str.replaceAll("-", "_");
  //replace special characters
  str = str.replace(/[&\/\\#,+()$~%.'":*?<>!?@´ªº^|£§{}]/g, "");
  //replace symbols
  chars.forEach(function (c) {
    str = str.replaceAll(c, "");
  });
  //replace accents
  accents.forEach(function (aObj) {
    aObj.accents.forEach(function (a) {
      str = str.replaceAll(a, aObj.base);
    });
  });

  return str.toLowerCase();
};

export const uid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + "-" + s4() + "-" + s4();
};

export const electionDay = () => {
  const day = sessionStorage.getItem(Config.sessionVar);

  return day !== null && day === "true";
};

export const removeSession = (key) => {
  sessionStorage.removeItem(key);
};

export const setSession = (key, val) => {
  sessionStorage.setItem(key, val);
};

export const getSession = (key) => {
  sessionStorage.getItem(key);
};

export const imgPath = (path) => {
  return path ? (path.indexOf("/") == 0 ? path : "/" + path) : path;
};

export const dateStringToObject = (string) => {
  const arr = string.split("-");

  return {
    day: arr[0] * 1,
    month: arr[1] * 1 - 1,
    year: arr[2] * 1,
  };
};

export const timeStringToObject = (string) => {
  const arr = string.split(":");

  return {
    hours: arr[0] * 1,
    minutes: arr[1] * 1,
    seconds: arr[2] * 1,
  };
};

export function formatHumanReadable({ date, time }) {
  // Separar a data e a hora
  const [day, month, year] = date.split("-").map(Number);
  const [hour, minute, second] = time.split(":").map(Number);

  // Criar objeto Date levando em conta o fuso horário de Cabo Verde (GMT-1)
  const caboVerdeDate = new Date(
    Date.UTC(year, month - 1, day, hour, minute, second)
  );
  caboVerdeDate.setUTCHours(caboVerdeDate.getUTCHours() + 1); // Ajustar para GMT-1

  // Obter a diferença entre agora e a data fornecida
  const now = new Date();
  const differenceInSeconds = Math.floor((now - caboVerdeDate) / 1000);

  // Converter a diferença em unidades de tempo legíveis
  const units = [
    { name: "ano", seconds: 365 * 24 * 60 * 60 },
    { name: "mês", seconds: 30 * 24 * 60 * 60 },
    { name: "dia", seconds: 24 * 60 * 60 },
    { name: "hora", seconds: 60 * 60 },
    { name: "minuto", seconds: 60 },
    { name: "segundo", seconds: 1 },
  ];

  for (const unit of units) {
    const count = Math.floor(differenceInSeconds / unit.seconds);
    if (count > 0) {
      return `há ${count} ${unit.name}${count > 1 ? "s" : ""}`;
    }
  }

  return "agora mesmo";
}

export function timeAgo(date) {
  const now = new Date();
  const givenDate = new Date(date);
  const diffInSeconds = Math.floor((now - givenDate) / 1000);

  if (diffInSeconds < 60) return `${diffInSeconds} segundos`;
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return `${diffInMinutes} minutos`;
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return `${diffInHours} horas`;
  const diffInDays = Math.floor(diffInHours / 24);
  return `${diffInDays} dias`;
}

export const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    const group = item[key];
    if (!result[group]) {
      result[group] = [];
    }
    result[group].push(item);
    return result;
  }, {});
};

export default {
  format: format,
};

export const api = async (endpoint, data, method = "POST", nocache = false) => {
  try {
    const response = await fetch(
      `${process?.env?.REACT_APP_API_URL}/${endpoint}${
        nocache ? `?v=${new Date().getTime()}` : ""
      }`,
      {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "x-api-key": process?.env?.REACT_APP_API_SECRET_KEY,
        },
        ...(data ? { body: JSON.stringify(data) } : {}),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("POST request failed:", error);
    throw error;
  }
};

export const windowSize = () => {
  const xs = window.innerWidth <= 575;
  const sm = window.innerWidth >= 575 && window.innerWidth <= 768;
  const md = window.innerWidth > 768 && window.innerWidth < 992;
  const lg = window.innerWidth > 992 && window.innerWidth < 1200;
  const xl = window.innerWidth > 1200;

  return { xs, sm, md, lg, xl };
};
