import React, { useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";

import Footer from "components/Theme/Footer";
import Votos from "./Votos";

import ElectedDeputies from "./ElectedDeputies";

import _ from "components/Translate";
import PartidosStats from "./PartidosStats";
import { fetchData, fetchVersion, setContext } from "store/elections/actions";
import Config from "config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Resumo = ({
  elections,
  candidatos,
  regions,
  deputados,
  selectedContext,
  regionCode,
}) => {
  const region = elections.selected;
  const dispatch = useDispatch();
  const history = useHistory();
  const camaraData =
    elections.data.camara.graphics && elections.data.camara.graphics[region]
      ? elections.data.camara.graphics[region]
      : {};
  const assembData =
    elections.data.assembleia.graphics &&
    elections.data.assembleia.graphics[region]
      ? elections.data.assembleia.graphics[region]
      : {};

  const isNacional = region === "nacional";

  useEffect(() => {
    if (selectedContext) {
      dispatch(setContext(selectedContext));
    }
    if (regionCode && selectedContext) {
      if (!elections?.update?.version) dispatch(fetchVersion(regionCode));
      else dispatch(fetchData(regionCode));
    }
  }, [regionCode, selectedContext]);

  useEffect(() => {
    if (regions?.data?.length > 0) {
      if (regionCode) {
        if (!regions?.data?.find((r) => r.code === regionCode)) {
          history.push(`/${Config.dashURL}/nacional/resumo`);
        }
      }
    }
  }, [regions]);

  return (
    <Row className="app-resumo">
      <Col className="w-100">
        <div
          className="m-0 d-flex flex-column flex-lg-row w-100 bg-component-blured component-radius stats-holder gap-y-3 mx-lg-n2 mb-5"
          style={{ gap: "0" }}
        >
          <div className="w-100 py-4 px-4 map-shadow t-m-camara">
            <div className="mb-3  d-lg-block" style={{ fontWeight: 500 }}>
              Câmara
            </div>
            <PartidosStats
              selectedRegion={regions?.data?.find(
                (r) => r.code === elections.selected
              )}
              elected={camaraData.elected}
              fetching={elections.isFetching}
              votos={camaraData.votos}
              candidatos={candidatos}
              deputados={deputados}
              electionContext={"camara"}
              context={
                elections.selected == "nacional" ? "Câmaras" : _("VOTOS")
              }
              electedLabel="Vereadores Eleitos"
            />
          </div>
          <div className="w-100 py-4 px-4 t-m-assembleia">
            <div className="mb-3  d-lg-block" style={{ fontWeight: 500 }}>
              Assembleia
            </div>
            <PartidosStats
              selectedRegion={regions?.data?.find(
                (r) => r.code === elections.selected
              )}
              elected={assembData.elected}
              fetching={elections.isFetching}
              votos={assembData.votos}
              candidatos={candidatos}
              deputados={deputados}
              electionContext={"assembleia"}
              context={
                elections.selected == "nacional" ? "Assembleias" : _("VOTOS")
              }
              electedLabel="Deputados Eleitos"
            />
          </div>
        </div>
        <Footer></Footer>
      </Col>
    </Row>
  );
};

export default connect((s) => ({ ...s }))(Resumo);
