import Config from "config";

const dateUeff = Config.dates.showUrnsEff;
const dateEl = Config.dates.showElection;

const initialState = {
  isFetching: false,
  fetched: false,
  currentDate: false,
  isElectionDay: false,
  isAffluenceDay: false,
  lastUpdate: null,
  status_site: null,
  electionDate: new Date(
    dateEl.year,
    dateEl.month,
    dateEl.day,
    dateEl.hours,
    dateEl.minutes,
    dateEl.seconds,
    dateEl.millis
  ),
  affluenceDate: new Date(
    dateUeff.year,
    dateUeff.month,
    dateUeff.day,
    dateUeff.hours,
    dateUeff.minutes,
    dateUeff.seconds,
    dateUeff.millis
  ),
  urnas: {
    version: {},
    lastUpdate: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCHING":
      return {
        ...state,
        isFetching: true,
      };
      break;

    case "TICK":
      const durationMs = performance.now() - action.startTime;

      let date = new Date(state.currentDate);

      if (state.currentDate) {
        const seconds = date.getSeconds();

        const durMillis = new Date(durationMs).getMilliseconds();

        date = date.setSeconds(seconds + 1);

        date = new Date(date).setMilliseconds(durMillis);

        return {
          ...state,

          currentDate: new Date(),
        };
      }

      break;

    case "SET_TIME":
      const isElectionDay =
        action.data.lastUpdate.getTime() >= state.electionDate.getTime();

      return {
        ...state,
        status_site: action.data.status_site,
        isElectionDay,
        lastUpdate: action.data.lastUpdate,
        currentDate: new Date(),
        isFetching: false,
        fetched: true,
      };

      break;
  }

  return state;
};
