import React, { useEffect, useRef } from "react";
import { Map } from "react-leaflet";
import { TileLayer } from "react-leaflet";
import _config from "../../config";
import { Marker } from "react-leaflet";
import { Popup } from "react-leaflet";
import L from "leaflet";

const LocalDeVotoMap = ({ position, title, info, description }) => {
  const mapRef = useRef();
  const markerRef = useRef(null);

  const handleMarkerClick = () => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  };

  const customIcon = L.divIcon({
    className: "custom-icon", // Add a CSS class for styling
    html: `<div class="app-map-icon closed shadow" style=""></div>`,
    iconSize: [30, 30],
    iconAnchor: [15, 15], // Center the icon
    popupAnchor: [0, -15], // Position popup above the icon
  });

  console.log(mapRef.current);

  return (
    <Map
      ref={mapRef}
      className=" w-100 h-100"
      fullscreenControl={true}
      center={position}
      zoom={16}
      whenReady={(o) => {
        const layers = o.target._layers;
        if (layers) {
          const markerLayer = Object.values(layers).find(
            (l) => l instanceof L.Marker
          );
          if (markerLayer) markerLayer.openPopup();
        }
      }}
    >
      <TileLayer {..._config.gis.basemap} minZoom={6} />
      <Marker
        position={position}
        ref={markerRef}
        icon={customIcon}
        eventHandlers={{
          click: handleMarkerClick,
        }}
      >
        <Popup>
          <div className="fw-700">{title}</div>
          <div>{description}</div>
          <div className="fw-600">{info}</div>
        </Popup>
      </Marker>
    </Map>
  );
};

export default LocalDeVotoMap;
