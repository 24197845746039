import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import mapDataWorld from "@highcharts/map-collection/custom/world.geo.json";
import baseMap from "resources/basemap.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import Config from "config";
import { uid } from "utils";
import { connect } from "react-redux";

const _basemap = {
  ...baseMap,
  features: baseMap.features.map((i) => ({
    ...i,
    properties: {
      ...i.properties,
      name: i.properties.key,
    },
  })),
};

const MapChart = ({ series, regions, elections, navigationPrefix = "" }) => {
  const history = useHistory();
  const [mapOptions, setMapOptions] = useState(null);
  const mapRef = useRef();
  const selectedRegion = elections?.selected;

  const getPoint = (selectedRegion) => {
    let point = null;
    if (mapRef.current?.chart?.series?.length) {
      point = mapRef.current?.chart?.series[0]?.points?.find(
        (p) => p.key === selectedRegion
      );
    }
    return point;
  };

  useEffect(() => {
    setMapOptions({
      title: false,
      tooltip: false,
      exporting: false,
      credits: false,
      chart: {
        animation: false,
      },
      mapNavigation: {
        enabled: false,
        buttons: true,
      },
      colorAxis: {
        min: 0,
      },
      xAxis: {
        minRange: -100,
      },
      legend: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
        format: "{point.name}",
      },
      plotOptions: {
        series: {
          animation: false,
          nullInteraction: true,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (series[0]) {
      const updatedOptions = {
        series: [
          {
            name: "mapdata-" + uid(),
            type: "map",
            mapData: _basemap,
            data: series,
            joinBy: ["key", "key"],
            events: {
              click: function (e, i) {
                const region = regions?.data.find((r) => {
                  return r.code == e.point.key;
                });
                history.push(
                  `/${Config.dashURL + navigationPrefix}/${region.code}/${
                    elections.selectedContext
                  }`
                );
              },
            },
          },
        ],
      };

      setMapOptions((p) => ({
        ...p,

        ...updatedOptions,
      }));
    }
  }, [history.location, series]);

  useEffect(() => {
    const elements = document.getElementsByClassName("highlighted");

    const point = getPoint(selectedRegion);

    if (point) {
      if (elements && elements[0])
        for (let x = 0; x < elements.length; x++)
          elements[x].classList.remove("highlighted");

      if (point.graphic && point.graphic.element)
        point.graphic.element.classList.add("highlighted");

      point.zoomTo();
    }
  }, [mapOptions]);

  return (
    <div>
      <div
        className="mt-5 "
        style={{ minHeight: "90%", height: "fit-content" }}
      >
        <style>
          {`
          [selected-region="${selectedRegion}"] .highcharts-name-${selectedRegion}{
            opacity:1!important
          }
          `}
        </style>

        {elections.first ? (
          <div
            className="d-flex w-100 align-items-center justify-content-center h-100 position-absolute top-0 left-0"
            style={{ minHeight: 300 }}
          >
            <div
              className="spinner-border spinner-border-sm body-color-light"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            {mapOptions && (
              <HighchartsReact
                ref={mapRef}
                highcharts={Highcharts}
                options={mapOptions}
                constructorType={"mapChart"}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapState = (s) => ({ ...s });

export default connect(mapState)(MapChart);
