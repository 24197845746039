import React, { Suspense, useEffect } from "react";
import Routes from "./routes";
import Loading from "components/Loading";
import configureStore from "store";
import Config from "config";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastProvider } from "react-toast-notifications";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

//const browserHistory = createBrowserHistory();

const store = configureStore();

const trackPageView = (url) => {
  if (window.gtag) {
    window.gtag("config", process?.env?.REACT_APP_GOOGLE_ANALYTICS_KEY, {
      page_path: url, // Replace with your Measurement ID
    });
  }
};

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Provider store={store}>
      <ToastProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Loading></Loading>
          <Router basename={Config.baseUrl}>
            <Analytics />
            <Routes></Routes>
          </Router>
        </Suspense>
      </ToastProvider>
    </Provider>
  );
};

export default App;
