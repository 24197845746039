import React from "react";

import { Row, Col } from "react-bootstrap";

import _ from "components/Translate";
import { LineHorizontal } from ".";
import { connect } from "react-redux";
import SimpleStat from "./SimpleStat";

const MesaDetails = ({ mesa, data, candidatos, prefix }) => {
  const getPartido = (id) => {
    return candidatos.filter((p) => id.toUpperCase() === p.id.toUpperCase())[0];
  };
  const pfx = prefix || "";

  const params = {
    vontantes: {
      value: data["votantes_value" + pfx],
      pct: data["votantes_pct" + pfx],
    },

    protestados: {
      value: data["protestados_value" + pfx],
      pct: data["protestados_pct" + pfx],
    },

    validos: {
      value: data["validos_value" + pfx],
      pct: data["validos_pct" + pfx],
    },

    brancos: {
      value: data["brancos_value" + pfx],
      pct: data["brancos_pct" + pfx],
    },

    abstencao: {
      value: data["abstencao_value" + pfx],
      pct: data["abstencao_pct" + pfx],
    },

    nulos: {
      value: data["nulos_value" + pfx],
      pct: data["nulos_pct" + pfx],
    },

    imagem_edital: {
      value: data["imagem_edital" + pfx],
    },
  };

  const voteValueParam = "votes" + pfx;
  const votes = data.votes;

  return (
    <div className="px-3 py-2 app-mesas-details-wrapper bg-color-lighter body-color">
      {params?.imagem_edital?.value && (
        <a
          className="text-accent small mb-3 d-inline-block"
          href={params?.imagem_edital?.value}
          target="_blank"
        >
          Ver edital
        </a>
      )}
      <Row className="m-0 ">
        <Col md="12" lg="4" className="p-0 p-lg-3 pr-lg-4">
          <LineHorizontal
            totalDescription={_("INSCRITOS")}
            title={_("VOTANTES")}
            value={params.vontantes.value}
            pct={params.vontantes.pct}
            total={data.total_inscritos}
          />
        </Col>
        <Col md="12" lg="8" className="p-0 p-lg-3 pl-lg-4">
          <SimpleStat
            className="dark small mt-5 mt-lg-0"
            flexWrapperClass="flex-wrap flex-lg-nowrap gap-4"
            transparent={false}
            colClass=""
            data={[
              {
                pct: params.validos.pct,
                value: params.validos.value,
                title: "Válidos",
                align: "left",
                margin: "0 0 15px 0",
              },
              {
                pct: params.protestados.pct,
                value: params.protestados.value,
                title: "Protestados",
                color: "#bbb5b7",
                align: "center",
                margin: "0 0 15px 0",
              },
              {
                pct: params.abstencao.pct,
                value: params.abstencao.value,
                title: _("ABSTENCAO"),
                margin: "0 0 15px 0",
              },
              {
                pct: params.nulos.pct,
                value: params.nulos.value,
                title: _("NULOS"),
                color: "#bbb5b7",
                align: "center",
                margin: "0 0 15px 0",
              },
              {
                pct: params.brancos.pct,
                value: params.brancos.value,
                title: _("BRANCOS"),
                color: "#8b8a90",
                align: "right",
                margin: "0 0 15px 0",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="mt-4 mx-0">
        <Col>
          <div className="d-flex flex-column flex-lg-row">
            {votes.map((partido) => {
              const partyData = getPartido(partido.id) || {};
              return (
                <div
                  party-id={partido.id}
                  className="d-block text-left text-lg-center align-items-center d-flex d-lg-block my-3"
                  key={partido.id}
                >
                  <div
                    style={{ width: 45, height: 40 }}
                    className="p-2 m-lg-auto"
                  >
                    <img
                      src={partyData.image}
                      style={{
                        maxWidth: "none",
                        height: 30,
                        width: 30,
                        borderRadius: "100%",
                      }}
                      alt="Partido"
                    />
                  </div>
                  <div className="p-2" style={{ width: 80 }}>
                    <div className="text-uppercase small">
                      <b>{partido.id}</b>
                    </div>
                  </div>
                  <div className="p-2">{partido[voteValueParam]}</div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect((s) => ({ ...s }))(MesaDetails);
