import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import {
  NaFrente,
  LineHorizontal,
  SimpleStats,
  Map,
  Mapcrumb,
  ResultadosZonas,
} from "components/Charts";
import Footer from "components/Theme/Footer";
import _ from "components/Translate";
import { fetchData, fetchVersion, setContext } from "store/elections/actions";
import PartidosStats from "components/Charts/PartidosStats";
import { useParams, useLocation } from "react-router-dom/cjs/react-router-dom";
import SearchZonas from "components/SearchZonas";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Config from "config";

const DashBoard = (props) => {
  const [state, setState] = useState({
    showArea: false,
    showIGRPMap: false,
  });

  const [selectedZona, setSelectedZona] = useState();

  const RunResultadosZonas = () => {
    setState({
      showArea: true,
    });
  };

  const ResetArea = () => {
    setState({
      showArea: false,
    });
  };

  const ToggleIGRPMap = (e) => {
    e.preventDefault();

    setState((oldState) => {
      return {
        ...oldState,
        showIGRPMap: !oldState.showIGRPMap,
      };
    });
    return false;
  };

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    elections,
    regions,
    candidatos,
    deputados,
    selectedContext,
    countdown,
  } = props;
  const { isFetching } = elections;
  const selectedRegion = elections.selected;
  const dataContext = elections.data[selectedContext];
  const chartsData =
    dataContext?.graphics && dataContext.graphics[selectedRegion]
      ? dataContext.graphics[selectedRegion]
      : null;
  const series = dataContext && dataContext.series ? dataContext.series : [];
  const hasZones =
    selectedRegion != "nacional" &&
    chartsData &&
    chartsData?.zonas &&
    chartsData?.zonas[0];
  const regionsData = regions.data || [];
  const selectedRegionItem = regionsData.filter(
    (r) => r.code === selectedRegion
  )[0];

  const selectedName = selectedRegionItem ? selectedRegionItem.name : "";

  const Ids =
    chartsData?.nafrente.ids && chartsData?.nafrente.ids[0]
      ? chartsData?.nafrente.ids
      : [];
  const bgColor =
    Ids.length === 1
      ? candidatos.find((item) => Ids[0] === item.id)?.color
      : "#f0f0f0";

  const rgbaColor = `rgba(${parseInt(bgColor?.slice(1, 3), 16)}, ${parseInt(
    bgColor?.slice(3, 5),
    16
  )}, ${parseInt(bgColor?.slice(5, 7), 16)}, .25)`;

  useEffect(() => {
    if (props.selectedContext) {
      props.setContext(props.selectedContext);
    }

    if (props.regionCode && selectedContext) {
      if (!props.elections?.update?.version)
        dispatch(fetchVersion(props.regionCode));
      else dispatch(fetchData(props.regionCode));
    }
  }, [props.regionCode, selectedContext]);

  useEffect(() => {
    if (props.regions?.data?.length > 0) {
      if (props.regionCode) {
        if (!props.regions?.data?.find((r) => r.code === props.regionCode)) {
          history.push(`/${Config.dashURL}/nacional/camara`);
        }
      }
    }
  }, [props.regions]);

  return (
    <div
      className="app-data-wrapper"
      region-selected={selectedRegion}
      context-selected={selectedContext}
      context={selectedContext}
    >
      <Row>
        <Col className="w-100">
          <Row
            className="m-0 bg-component-blured px-0 px-lg-3 py-4 component-radius stats-holder gap-y-3 mx-lg-n2"
            style={{ gap: " 16px 0" }}
          >
            <Col md="6" lg="3" xl="3" className={`px-lg-2`}>
              <div
                className="spacer component-radius component-shadow w-100 p-3 h-100"
                style={{ background: rgbaColor }}
              >
                <NaFrente
                  isFetching={isFetching}
                  {...chartsData?.nafrente}
                  context={selectedContext}
                  loading={elections.first}
                ></NaFrente>
              </div>
            </Col>
            <Col sm="6" md="6" lg="3" className=" w-100 px-lg-2">
              <div className="spacer component-radius component-shadow component-border border bg-component p-3">
                <LineHorizontal
                  title={_("MESAS")}
                  totalLabel="Total de Mesas"
                  {...chartsData?.mesas}
                  className="t-s-mesas"
                  loading={elections.first}
                />
              </div>
            </Col>
            <Col sm="6" lg="3" className=" w-100 px-lg-2">
              <div className="spacer component-radius component-shadow  component-border border bg-component p-3">
                <LineHorizontal
                  totalDescription={_("INSCRITOS")}
                  title={_("VOTANTES")}
                  {...chartsData?.votantes}
                  className="t-s-voters"
                  loading={elections.first}
                ></LineHorizontal>
              </div>
            </Col>
            <Col className="w-100 px-lg-2" md="6" lg="3">
              <div className="spacer component-radius component-shadow  component-border border bg-component p-3">
                <SimpleStats
                  loading={elections.first}
                  flexWrapperClass="gap-1"
                  data={[
                    {
                      pct: chartsData?.votantes.abstention_pct,
                      value: chartsData?.votantes.abstention,
                      title: _("ABSTENCAO"),
                    },
                    {
                      ...chartsData?.nulos,
                      title: _("NULOS"),
                      color: "#bbb5b7",
                      align: "center",
                    },
                    {
                      ...chartsData?.brancos,
                      title: _("BRANCOS"),
                      color: "#8b8a90",
                      align: "right",
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className=" mt-4">
        <Col className=" mb-lg-0 px-lg-2">
          <Card className="stats-shadow border-0 mb-4 bg-transparent order-2">
            <Card.Body className="p-0 ">
              <Row className="m-0 ">
                <Col
                  lg="6"
                  className="py-3 px-3 bg-map-component holder-map map-shadow position-relative component-radius mr-lg-n2 order-sm-2"
                >
                  <div className="d-flex gap-2 ">
                    <Mapcrumb
                      navigationPrefix={props.navigationPrefix}
                    ></Mapcrumb>
                    {/**
                     * 
                     * <div className="ml-auto">
                      <SearchZonas
                        currentRegion={regionsData.find(
                          (r) => r.code === props.elections?.selected
                        )}
                        onSelect={({ region, zona }) => {
                          dispatch(fetchData(region?.code));
                          setSelectedZona(zona);
                          setState({
                            showArea: true,
                          });
                        }}
                      />
                    </div>
                     * 
                     */}

                    {hasZones && (
                      <div className="ml-auto">
                        <button
                          onClick={() => RunResultadosZonas()}
                          className="btn bg-color btn-sm  bg-component component-radius app-zonas-modal-btn border component-border  app-mapcrumb px-3 py-2 "
                        >
                          <b>Zonas</b>
                        </button>
                      </div>
                    )}
                  </div>
                  <Map
                    navigationPrefix={props.navigationPrefix}
                    loading={props.elections?.first}
                    series={[...series]}
                  ></Map>
                </Col>
                <Col
                  lg="6"
                  className="px-3 px-md-5 pt-5 pb-4 py-lg-4 px-lg-5 bg-component-blured component-radius order-first order-lg-2 "
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <PartidosStats
                    selectedRegion={props.regions?.data?.find(
                      (r) => r.code === props.elections.selected
                    )}
                    regions={props.regions.data}
                    elected={chartsData?.elected}
                    fetching={isFetching}
                    votos={chartsData?.votos}
                    candidatos={candidatos}
                    deputados={deputados}
                    electionContext={selectedContext}
                    context={
                      selectedRegion === "nacional"
                        ? _(selectedContext.toUpperCase() + "S")
                        : _("VOTOS")
                    }
                    electedLabel={`${
                      selectedContext === "camara" ? "Vereadores" : "Deputados"
                    } Eleitos`}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xl="12">
          <Footer></Footer>
        </Col>
      </Row>

      {state.showArea && hasZones && (
        <ResultadosZonas
          {...state}
          resetArea={ResetArea}
          candidatos={candidatos}
          regions={selectedName}
          context={selectedContext}
          zonas={chartsData?.zonas}
          selectedCode={selectedRegion}
          selectedZona={selectedZona}
        />
      )}
    </div>
  );
};

export default connect(
  (s) => ({ ...s }),
  (dispatch, props) => {
    return {
      setContext: (context) => dispatch(setContext(context)),
    };
  }
)(DashBoard);
