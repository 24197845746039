import axios from "axios";

import { useToasts } from "react-toast-notifications";

import { trackPromise } from "react-promise-tracker";

import { SET_DATA, SET_THEME, SET_UPDATE } from "./action-type";

export const requestData = (region) => ({
  type: "REQUEST_DATA",
  region,
});

export const setRegion = (data) => ({
  type: "SET_REGION",
  data,
});

export const fetching = () => ({
  type: "FETCHING",
});

export const finishFetching = () => ({
  type: "FINICHED_FETCHING",
});

export const setData = (code, data) => ({
  type: SET_DATA,
  data,
  code,
});

export const setUpdate = (data) => ({
  type: SET_UPDATE,
  data,
});

export const setTheme = (data) => ({
  type: SET_THEME,
  data,
});

export const setContext = (data) => ({
  type: "SET_CONTEXT",
  data,
});

export const setSiteStatus = (data) => ({
  type: "SET_SITE_STATUS",
  data,
});

export const refresh = () => ({
  type: "RERESH_DATA",
});

export const fetchVersion = (code, getData = true) => {
  return (dispatch, getState) => {
    const nocache = new Date().getTime();

    const { update, selected } = getState().elections;

    return axios.get("/data/version.json?_=" + nocache).then((d) => {
      let updated = false;
      let dataRequest;

      dispatch(setUpdate({
        ...update,
        time: d.data.time,
        date: d.data.date
      }));

      if (
        d.data.version != update.version ||
        d.data.status_site !== update.status_site
      ) {
        dispatch(setUpdate(d.data));
        if (getData) {
          dataRequest = dispatch(fetchData(code || selected, d.data.version));
          updated = true;
        }
      }
      return {
        updated,
        time: d.data.time,
        dataRequest,
      };
    });
  };
};

export const fetchData = (_code, v) => {
  const code = _code || "nacional";

  return (dispatch, getState, p) => {
    const state = getState();

    const { elections } = state;

    const version = v || elections.update.version;

    const hasData = elections.data.camara.graphics[_code];

    if (elections.clean) dispatch(fetching());

    const camaraRequest = axios.get(`/data/${version}/camara/${code}.json`);

    const assembleiaRequest = axios.get(
      `/data/${version}/assembleia/${code}.json`
    );

    return trackPromise(
      axios
        .all([camaraRequest, assembleiaRequest])
        .then(
          axios.spread((...responses) => {
            const camaraData = responses[0];

            const assembleiaData = responses[1];

            dispatch(
              setData(code, {
                camara: camaraData.data,

                assembleia: assembleiaData.data,
              })
            );

            dispatch(finishFetching());
          })
        )
        .catch((errors) => {
          console.log(errors);
        })
    );

    //}
  };
};
