import "react-leaflet-markercluster/dist/styles.min.css";

import React, { useEffect, useRef, useState } from "react";

import { Map, TileLayer } from "react-leaflet";

import MarkerClusterGroup from "react-leaflet-markercluster";

import GeoJSON from "./GeoJSON";

import { Form, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

import IonIcon from "components/Icon";
import Skeleton from "react-loading-skeleton";

window.DataFeatures = null;

const ResultList = ({
  className = "",
  searchList,
  searchInput,
  searching,
  onClick,
  zoomTo,
}) => {
  const resHolder = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (resHolder.current) {
        if (window.innerWidth < 992) window.scrollTo(0, 150);
      }
    }, 500);
  }, [searchInput.length]);
  return (
    <>
      {searchInput.length > 0 && (
        <div className="map-search-wrapper" ref={resHolder}>
          <div className={`${className} shadow`}>
            <ListGroup
              style={{ maxHeight: 300, overflow: "auto" }}
              className="map-search-results-list component-radius bg-color"
            >
              {searchList[0] ? (
                <>
                  {searchList.map((item, i) => {
                    const referencia =
                      item.properties.MES_REFERE ||
                      item.properties.MES_REFERENCIA;
                    const local =
                      item.properties.LOCAL_MORA || item.properties.DESCRICAO;
                    const extra =
                      item.properties.POS_NOME || item.properties.POSTO;
                    const concelho =
                      item.properties.CON_DESCRI || item.properties.CONCELHO;
                    const isGeoRefered =
                      item.properties.LATITUDE ||
                      item.properties.GEOREFEREN === 1;

                    return (
                      <ListGroup.Item
                        className="cursor-pointer py-2 p-0 d-flex align-items-center bg-color border-light w-100 "
                        key={i}
                      >
                        <div
                          className="px-3 py-2 w-100"
                          onClick={() => onClick(item)}
                          style={{
                            maxWidth: "calc(100% - 48px)",
                          }}
                        >
                          <div>
                            <div className="fw-600">{referencia}</div>
                          </div>
                          <div>
                            <span className="fw-500">{local}</span>{" "}
                          </div>
                          <div>
                            {concelho && (
                              <span className="small fw-500">{concelho}</span>
                            )}
                            {extra && (
                              <span className="ml-2 small">{extra}</span>
                            )}
                          </div>

                          {!isGeoRefered && (
                            <div className="d-block small">
                              Esta mesa não está georreferenciada
                            </div>
                          )}
                        </div>
                        {isGeoRefered && (
                          <div
                            className="py-2 px-3 cursor-pointer  hover-active"
                            onClick={() => zoomTo(item)}
                          >
                            <OverlayTrigger
                              placement="left"
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip style={{ zIndex: 10000 }}>
                                  Centrar no mapa
                                </Tooltip>
                              }
                            >
                              <span className="body-color">
                                <IonIcon
                                  name="locate-outline"
                                  size="16"
                                  color="--var(--body-color)"
                                />
                              </span>
                            </OverlayTrigger>
                          </div>
                        )}
                      </ListGroup.Item>
                    );
                  })}
                </>
              ) : searching ? (
                <div className="p-3">A pesquisar...</div>
              ) : (
                <div className="p-3">
                  Nenhuma mesa correspondente à sua pesquisa.
                </div>
              )}
            </ListGroup>
          </div>
        </div>
      )}
    </>
  );
};

const _MapView = (props) => {
  const L = window.L;

  let debounceTimeout = useRef(null);
  const [searchList, setSearchList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searching, setSearching] = useState(false);
  const [mapSize, setMapSize] = useState(360);
  const mapRef = useRef();
  const inputRef = useRef();

  const isSmallDevice = window.innerWidth < 992;

  const hasResults = searchList[0] ? true : false;

  const clearSearch = () => {
    setSearchList([]);
  };

  const goHome = () => {
    try {
      if (mapRef.current?.leafletElement) {
        mapRef.current.leafletElement.setView([16, -24], 8);
      }
    } catch (err) {}
  };

  const zoomTo = (o) => {
    const leafletMap = mapRef.current?.leafletElement;

    if (leafletMap) {
      leafletMap.setView(
        [o.geometry.coordinates[1], o.geometry.coordinates[0]],
        17
      );
      resizeMap();
      setTimeout(() => {
        const marker = Object.values(leafletMap._layers)?.find(
          (layer) =>
            layer instanceof L.Marker &&
            layer.feature?.properties?.MES_REFERE === o.properties.MES_REFERE
        );
        if (marker) marker.openPopup();
      }, 500);
    }
  };

  const handleKey = (e) => {
    let val = e.target.value;
    setSearchInput(val);
    clearTimeout(debounceTimeout.current);
    setSearching(true);
    debounceTimeout = setTimeout(() => {
      if (
        val &&
        props.data &&
        props.data.features &&
        props.data.features[0 && !searching]
      ) {
        val = val.toUpperCase();
        const items = props.data.features.filter((item) => {
          const { properties } = item;
          const concelhValid =
            properties.CONCELHO &&
            properties.CONCELHO.toUpperCase().indexOf(val) >= 0;
          const descValid =
            properties.DESCRICAO &&
            properties.DESCRICAO.toUpperCase().indexOf(val) >= 0;
          const refMesaValid =
            properties.MES_REFERE &&
            properties.MES_REFERE.toUpperCase().indexOf(val) >= 0;
          const postoValid =
            properties.POSTO &&
            properties.POSTO.toUpperCase().indexOf(val) >= 0;

          return concelhValid || descValid || refMesaValid || postoValid;
        });
        setSearchList(items);
        setTimeout(() => {
          setSearching(false);
        });
      } else {
        clearSearch();
      }
    }, 0);
  };

  const resizeMap = () => {
    setTimeout(() => {
      if (mapRef.current?.leafletElement)
        mapRef.current.leafletElement.invalidateSize();
    }, 100);
  };

  const handleClear = () => {
    clearSearch();
    setSearchInput("");
    resizeMap();
    setSearching(false);

    const input = document.querySelector(".mesas-search-control");
    if (input) input.focus();
  };

  const calcMapHeight = () => {
    const headerSize =
      (document.querySelector(".app-header-wrapper>.row")?.clientHeight ||
        125) + 60;

    setMapSize(`calc(100vh - ${headerSize}px )`);
    resizeMap();
  };

  useEffect(() => {
    calcMapHeight();

    window.addEventListener("resize", calcMapHeight);

    return () => {
      window.removeEventListener("resize", calcMapHeight);
    };
  }, []);

  return (
    <div
      className="position-relative pr-2"
      style={{ height: "100vh", minHeight: "400px", maxHeight: mapSize }}
    >
      <div className=" small app-map-searcher ml-auto" style={{}}>
        <Form.Group className="position-relative mb-0">
          <Form.Control
            ref={inputRef}
            onChange={(e) => handleKey(e)}
            placeholder="Pesquise código/local da mesa..."
            type="text"
            style={{ border: 0, height: 40 }}
            value={searchInput}
            className="bg-color shadow component-radius mesas-search-control"
          ></Form.Control>
          {searchInput.length > 0 && (
            <div
              className="position-absolute p-2 cursor-pointer"
              onClick={() => {
                handleClear();
              }}
              style={{ top: 0, right: 0 }}
            >
              <IonIcon name="close-outline" size="20" />
            </div>
          )}
        </Form.Group>
        {!isSmallDevice && (
          <ResultList
            className="mt-1"
            searchList={searchList}
            searchInput={searchInput}
            searching={searching}
            regionsList={props.regionsList}
            onClick={(item) => {
              props.markerClick(item);
              zoomTo(item);
            }}
            zoomTo={zoomTo}
          />
        )}
      </div>
      {isSmallDevice && (
        <ResultList
          className="map-search-results-sm"
          searchList={searchList}
          searchInput={searchInput}
          searching={searching}
          regionsList={props.regionsList}
          onClick={(item) => {
            props.markerClick(item);
            zoomTo(item);
          }}
          zoomTo={zoomTo}
        />
      )}
      <Map
        ref={mapRef}
        className="map-view position-absolute w-100 left-0 top-0 "
        center={props.center}
        zoom={props.zoom}
        zoomControl={true}
        fullscreenControl={true}
        onClick={props.mapClick}
        whenReady={(o) => {
          if (props.whenReady) {
            props.whenReady(o);
          }
        }}
        onMoveend={props.onMoveend}
        onZoomend={props.onMoveend}
        style={{
          height: hasResults && isSmallDevice ? 320 : "100%",
        }}
      >
        <TileLayer {...props.config.basemap} minZoom={6} />

        <MarkerClusterGroup
          spiderfyOnMaxZoom={false}
          showCoverageOnHover={false}
          zoomToBoundsOnClick={true}
          id="app-markers-cluster"
          disableClusteringAtZoom={15}
          maxClusterRadius={30}
          iconCreateFunction={(cluster) => {
            const L = window.L;
            const count = cluster.getChildCount();
            const digits = (count + "").length;

            return L.divIcon({
              html: count,
              className: "cluster blinker  digits-" + digits,
              iconSize: null,
            });
          }}
        >
          <GeoJSON {...props} id="app-data-json"></GeoJSON>
        </MarkerClusterGroup>
      </Map>

      <div
        onClick={() => goHome()}
        className="position-absolute bg-color small body-color text-center d-flex cursor-pointer app-map-home"
      >
        <i className="fas fa-home"></i>
      </div>
    </div>
  );
};

export default _MapView;
