import React, { useEffect, useState } from "react";
import Data from "./Data";
import Nav from "./Nav";
import Side from "./Side";
import Loading from "components/Loading";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";

import { useToasts } from "react-toast-notifications";
import { fetchData } from "store/elections/actions";
import { CSSTransition } from "react-transition-group";
import AppLoading from "components/AppLoading";
import GetVersionData from "./Data/get-version";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import config from "../../config";
import IonIcon from "components/Icon";

const Theme = (props) => {
  const selectedCode = props.elections.selected;
  const newUpdates = props.elections.newUpdates;
  const showSidebar = props.showSidebar === false ? false : true;

  const [visible, setVisible] = useState(true);
  const [sideClss, setSideClss] = useState("");
  const [open, setOpen] = useState(false);

  const isTabletOrMobile = () => window.innerWidth < 1200;

  const { addToast } = useToasts();

  const handleCollpase = () => {
    if (isTabletOrMobile()) {
      const clss = sideClss == "tablet-side" ? "" : "tablet-side";

      setSideClss(clss);
    } else setVisible(!visible);
  };

  const afterRegionSelectOnTablet = () => {
    if (isTabletOrMobile())
      setSideClss(sideClss == "tablet-side" ? "" : "tablet-side");
  };

  const getRegion = (id) =>
    props.regions.data.filter((region) => region.code === id)[0];

  const next = () => {
    const clickableArr = props.regions.detailedData.filter(
      (d) => d.parent_code !== ""
    );
    let index = clickableArr.indexOf(
      clickableArr.filter((i) => i.code === selectedCode)[0]
    );
    const nextItem = clickableArr[index + 1];
    if (nextItem) props.setRegion(nextItem.code);
  };

  const prev = () => {
    const clickableArr = props.regions.detailedData.filter(
      (d) => d.parent_code !== ""
    );
    let index = clickableArr.indexOf(
      clickableArr.filter((i) => i.code === selectedCode)[0]
    );

    const prevItem = clickableArr[index - 1];

    if (index == 0) {
      props.setRegion("nacional");
      return;
    }

    if (prevItem) props.setRegion(prevItem.code);
  };

  const DisplayUpdate = (props) => {
    const items = props.items || [];
    const message = props.message || "";
    return (
      <div className="small">
        <div
          className="py-2 position-sticky top-0 px-0 bg-white"
          style={{ zIndex: 1 }}
        >
          {message}
        </div>
        <ListGroup
          variant="flush"
          className="p-0"
          style={{ maxHeight: "90vh", overflow: "auto" }}
        >
          {items.map((mesa) => {
            const region = getRegion(mesa.key);
            return region ? (
              <ListGroup.Item
                key={mesa.key}
                style={{ fontSize: 10 }}
                action
                className="app-updates-item d-flex gap-1 px-0 py-1 "
                onClick={() => {
                  if (typeof props.onClick === "function")
                    props.onClick(mesa.key);
                }}
              >
                <IonIcon name="checkmark-outline" size="16"></IonIcon>
                <span className="fs-14">{region.name}</span>
              </ListGroup.Item>
            ) : (
              <></>
            );
          })}
        </ListGroup>
      </div>
    );
  };

  const showRegionMenu = props.showRegionMenu === false ? false : true;

  useEffect(() => {
    if (newUpdates[0]) {
      if (newUpdates.length >= 3)
        addToast(
          <DisplayUpdate
            items={newUpdates}
            onClick={(v) => {
              props.history.push(
                `/${config.dashURL + (props.navigationPrefix || "")}/${v}`
              );
            }}
            message={
              <div className="d-flex align-items-center gap-2">
                <b>100%</b>
                <span>Mesas apuradas em</span>
              </div>
            }
          />,
          {
            appearance: "info",
            autoDismiss: true,
            autoDismissTimeout: 10000,
          }
        );
      else
        newUpdates.map((mesa) => {
          const region = getRegion(mesa.key);
          if (region)
            addToast(
              <DisplayUpdate
                items={[mesa]}
                onClick={() => {
                  props.history.push(
                    `/${config.dashURL + (props.navigationPrefix || "")}/${
                      mesa.key
                    }`
                  );
                }}
                message={
                  <>
                    <b>100%</b>
                    <span> de mesas apuradas em</span>
                  </>
                }
              ></DisplayUpdate>,
              {
                appearance: "info",
                autoDismiss: true,
                autoDismissTimeout: 10000,
              }
            );
        });
    }
  }, [newUpdates]);

  const { theme } = props.elections;

  useEffect(() => {
    document.querySelector("body").setAttribute("theme", theme);
  }, [theme]);

  useEffect(() => {
    if (selectedCode && isTabletOrMobile()) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setSideClss("");
    }
  }, [selectedCode]);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 800);
  }, []);

  useEffect(() => {
    if (isTabletOrMobile()) {
      const bodyElement = document.querySelector("body");
      if (bodyElement) {
        if (sideClss === "tablet-side") {
          bodyElement.classList.add("overflow-hidden");
        } else {
          bodyElement.classList.remove("overflow-hidden");
        }
      }
    }
  }, [sideClss]);



  return (
    <GetVersionData update={true}>
      <Data>
        {!open && <AppLoading />}
        <CSSTransition
          in={open}
          timeout={400} // Animation duration in ms
          classNames="page"
          unmountOnExit
        >
          <div className="page">
            <Loading></Loading>
            <Graphics />

            <Container
              fluid
              id={`app-theme`}
              className={`pb-3 px-0 ${sideClss} ${
                showSidebar ? "" : "no-sidebar"
              } `}
              selected-region={selectedCode}
              theme={theme || "light"}

            >
              <div className="app-container position-relative">
                <Row className="app-contents px-xl-4 px-sm-2 mx-0">
                  {showSidebar && (
                    <Col
                      style={visible ? { maxWidth: 190 } : { maxWidth: 60 }}
                      className={`  pl-0 pr-0 d-none d-xl-block app-sidebar-col `}
                    >
                      {props.title && (
                        <Col
                          className="h6 body-color py-3 align-items-center d-flex m-0 px-xl-0 "
                          xs="12"
                        >
                          <div className="ml-2">{props.title}</div>
                        </Col>
                      )}
                      <Side
                        visible={visible}
                        afterFetch={afterRegionSelectOnTablet}
                        handleCollpase={handleCollpase}
                        navigationPrefix={props.navigationPrefix}
                      />

                      <div
                        className="app-sidebar-x position-fixed d-xl-none"
                        onClick={handleCollpase}
                        style={{
                          fontSize: 25,
                          left: 270,
                          top: 10,
                          zIndex: 1005,
                        }}
                      >
                        <span className="close-icon body-color"></span>
                      </div>

                      <div
                        className="app-sidebar-overlay"
                        onClick={handleCollpase}
                      ></div>
                    </Col>
                  )}
                  <Col className="p-0">
                    <div className="app-header-wrapper px-xl-4 ">
                      <Nav
                        collapse={handleCollpase}
                        next={next}
                        prev={prev}
                        showSidebar={showSidebar}
                        hideResponsiveMenu={props.hideResponsiveMenu}
                        navigationPrefix={props.navigationPrefix}
                      />
                    </div>
                    <main role="main" className="pt-0">
                      <div>{props.children}</div>
                    </main>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </CSSTransition>
      </Data>
    </GetVersionData>
  );
};

export const Graphics = () => (
  <>
    <img
      src="/images/theme/light/eclipse-top-left.svg"
      className="top-left position-absolute "
    />
    <img
      src="/images/theme/light/flag.png"
      className="position-absolute top-right"
      style={{ top: 0, right: 0 }}
    />

    <img
      src="/images/theme/light/eclipse-bottom-right.svg"
      className="bottom-left position-fixed "
      style={{ bottom: -200, right: 0 }}
    />
  </>
);

export default connect(
  (state) => ({ ...state }),
  (dispatch) => ({
    setRegion: (code) => dispatch(fetchData(code)),
  })
)(withRouter(Theme));

export { default as Layouts } from "./Layouts";
