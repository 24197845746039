export default {
  baseUrl: "/",
  dashURL: "resultados",
  mapURL: "mesas",
  homepage: process.env.REACT_APP_HOMEPAGE || "/",
  versionRequestTimeout: 5000,
  dates: {
    onRouteEnterValidation: true,
    showElection: {
      year: 2024,
      month: 11,
      day: 1,
      hours: 19,
      minutes: 0,
      seconds: 0,
      millis: 0,
    },
    showUrnsEff: {
      year: 2024,
      month: 11,
      day: 1,
      hours: 15,
      minutes: 0,
      seconds: 0,
      millis: 0,
    },
  },

  local_de_voto: {
    show: true,
  },

  gis: {
    dataUrl: "/map/mesas.json",
    basemap: {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
    },
  },

  cvTimeUrl:
    "https://script.googleusercontent.com/macros/echo?user_content_key=TqcbPV3fOu_r7Wz5EOZR9kOK5Zuft9XR8su1Ur08XRQpvWTuY1j7rJkZtuuML0oUcaRc7s2DKKpR-VY4whipkP-IJFV5NZJWm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJ9GRkcRevgjTvo8Dc32iw_BLJPcPfRdVKhJT5HNzQuXEeN3QFwl2n0M6ZmO-h7C6bwVq0tbM60-56s-xPRFShKrLcZF-iq4I11ZGgGPA0h4&lib=MwxUjRcLr2qLlnVOLh12wSNkqcO1Ikdrk",

  site: {
    name: "Autárquicas 2024",
    logo: "",
  },

  defaults: {
    context: {
      camara: {
        label: "Câmara",
      },
      assembleia: {
        label: "Assembleia",
      },
    },
    regions: {
      selected: "nacional",
    },
  },

  igrp: {
    env: "",
  },
};
