import React from "react";
import { format, imgPath } from "utils";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TextTransition from "react-text-transition";
const Loading = () => {
  const arr = (function () {
    var r = [];
    for (var i = 0; i < 7; i++) {
      r.push(i);
    }
    return r;
  })();

  return arr.map((i) => (
    <div
      className="pb-3 position-relative mb-1 col-sm-6 col-md-12 px-lg-0"
      key={i}
    >
      <div className="d-flex align-items-center">
        <Skeleton width={26} height={26} circle={true}></Skeleton>
        <div className="info-votos pl-3 w-100">
          <div className="d-flex">
            <div
              className="d-flex font-weight-bold position-relative"
              style={{ top: -2 }}
            >
              <Skeleton width={40}></Skeleton>
            </div>
          </div>
          <Skeleton width={"100%"}></Skeleton>
        </div>
      </div>
    </div>
  ));
};

export default (props) => {
  const getCandidato = (id) => {
    return props.candidatos.filter((item) => item.id === id)[0] || {};
  };

  const title =
    props.title && typeof props.title === "string" ? props.title : "";

  return (
    <SkeletonTheme color="#e8e8e8" highlightColor="#fff">
      <div className=" app-votos px-2">
        <h6 className="text-capitalize mb-5 font-weight-bold body-color">
          <TextTransition text={title} />
          {props.subtitle && (
            <small>
              <TextTransition text={props.subtitle} />
            </small>
          )}
        </h6>
        <div className="row m-0">
          {props.fetching && <Loading></Loading>}

          {props.votos &&
            props.votos[0] &&
            props.votos.map((item, i) => {
              return (
                <div
                  party-id={item.id}
                  className="pb-3 position-relative mb-1 col-sm-6 col-md-12 px-lg-0"
                  key={item.id + "-" + i}
                >
                  <div className="d-flex align-items-center">
                    <div className="text-center app-voto-candidato d-flex align-items-center">
                      <img src={imgPath(getCandidato(item.id).image)} alt="" />
                    </div>
                    <div className="info-votos pl-3 w-100">
                      <div
                        className="d-flex font-weight-bold position-relative"
                        style={{ top: -2 }}
                      >
                        <span className=" app-voto-name">{item.id}</span>
                        <span className="ml-auto pr-2 app-voto-value text-right body-color">
                          {format(item.votes)}
                        </span>
                        <span
                          className=" app-voto-pct text-right"
                          style={{ minWidth: "45px" }}
                        >
                          {item.pct}%
                        </span>
                        <span
                          className=" app-voto-pct text-right"
                          style={{ minWidth: "45px" }}
                        >
                          {item.pct}%
                        </span>
                      </div>
                      <div className="app-line-h-bar-wrapper">
                        <div
                          className="app-line-h-bar-pct"
                          style={{
                            width: `${item.pct}%`,
                            background: `${getCandidato(item.id).color}`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </SkeletonTheme>
  );
};
