import React from "react";

import { GeoJSON, Popup } from "react-leaflet";

class _GeoJSON extends React.Component {
  unique(data) {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + "_" + s4();
  }

  render() {
    return this.props.data ? (
      <GeoJSON
        //id={key}

        key={this.props.id}
        onClick={
          this.props.markerClick
            ? (e) => {
                this.props.markerClick(e);
              }
            : false
        }
        data={this.props.data}
        onMouseOver={this.props.onMouseOver}
        onMouseOut={this.props.onMouseOut}
        onEachFeature={this.props.onEachFeature}
        pointToLayer={(feature, latlng) => {
          const L = window.L;
          const icon = new L.DivIcon({
            html: "<span  class='app-map-icon shadow'></span>",
            className: "text-center ",
            iconSize: new L.Point(10, 10),
          });
          return L.marker(latlng, { icon: icon });
        }}
      ></GeoJSON>
    ) : (
      <></>
    );
  }
}

export default _GeoJSON;
